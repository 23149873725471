import React, {Component} from 'react';
import './Toolbar.css';
import PropTypes from 'prop-types';
import CustomButton from "../common/button/CustomButton";
import {MODES} from "../../reducers/mode/mode";
import SaveModelModal from "./saveModelModal/SaveModelModal.container";
import {ACTIONS} from "./saveModelModal/SaveModelModal";

class Toolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalAction: null
        };
    }

    render() {
        return (
            <div className="toolbar">
                <div className='left-align'>
                </div>
                <div className='right-align'>
                    {this.renderResetButton()}
                    {this.renderChangeModeButton()}
                    <div className='toolbar-btn'>
                        <CustomButton variant="outlined" onClick={this.props.onLogout}>Log out</CustomButton>
                    </div>
                </div>
                <SaveModelModal action={this.state.modalAction}/>
            </div>
        );
    }

    renderChangeModeButton = () => {
        switch (this.props.mode) {
            case MODES.MAIN:
                return (
                    <div className='toolbar-btn'>
                        <CustomButton variant="outlined" onClick={this.props.onSetTrainingMode}
                                      disabled={this.props.fetching}>
                            Start training session
                        </CustomButton>
                    </div>
                );
            case MODES.TRAINING:
                return (
                    <div className='toolbar-btn'>
                        <CustomButton variant="outlined" onClick={this.handleSaveButtonClick}
                                      disabled={this.props.fetching}>
                            Save model and end training session
                        </CustomButton>
                    </div>
                );
            case MODES.DEPLOY:
                return (
                    <div className='toolbar-btn'>
                        <CustomButton variant="outlined" onClick={this.handleFinishDeploymentButtonClick}
                                      disabled={this.props.fetching}>
                            Back to the main screen
                        </CustomButton>
                    </div>
                );
            default:
                return;
        }
    };

    renderResetButton = () => {
        if (this.props.mode === MODES.TRAINING || this.props.mode === MODES.MAIN) {
            return (
                <div className='toolbar-btn'>
                    <CustomButton variant="outlined" onClick={this.handleResetButtonClick}
                                  disabled={this.props.fetching}>
                        Reset
                    </CustomButton>
                </div>
            )
        }
    };

    handleResetButtonClick = () => {
        this.props.onResetModel();
    };

    handleSaveButtonClick = () => {
        if (!this.props.hasPatterns) {
            this.props.onRemoveModel();
        } else {
            let action;
            if (this.props.modelCreated) {
                action = ACTIONS.SAVE;
            } else {
                action = ACTIONS.TRAIN_SAVE
            }
            this.setState({modalAction: action});
            this.props.onSave();
        }
    };

    handleFinishDeploymentButtonClick = () => {
        this.props.onFinishDeployment();
    };
}

Toolbar.propTypes = {
    mode: PropTypes.string.isRequired,
    fetching: PropTypes.bool,
    modelCreated: PropTypes.bool,
    hasPatterns: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    onSetMainMode: PropTypes.func.isRequired,
    onSetTrainingMode: PropTypes.func.isRequired,
    onRemoveModel: PropTypes.func.isRequired,
    onResetModel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onFinishDeployment: PropTypes.func.isRequired,
};

export default Toolbar;
