import { connect } from 'react-redux';
import SaveModelModal from "./SaveModelModal";
import {onSaveModel, removeModel, trainingModel} from "../../../reducers/models/models";
import {closeModal, getActiveModal, SAVE_MODEL_MODAL} from "../../../reducers/modal/modal";

const mapStateToProps = state => {
    return {
        isOpen: getActiveModal(state) === SAVE_MODEL_MODAL,
        training: trainingModel(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDiscardChanges: () => {
            dispatch(removeModel());
        },
        onSave: (name) => {
            dispatch(onSaveModel(name));
        },
        onClose: () => {
            dispatch(closeModal());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveModelModal);