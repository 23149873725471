import {createAction, handleActions} from 'redux-actions';
import {StorageApi} from '../../utils/storageApi';
import {Api} from "../../api/api";
import {temporaryModelCreated, removeModel} from "../models/models";

//- Actions
export const logout = () => (dispatch, getState) => {
    if (temporaryModelCreated(getState())) {
        dispatch(removeModel())
    }
    StorageApi.removeToken();
    dispatch(fetchLogout())
};

export const fetchLogout = createAction('LOGOUT',
    () => Api.logout()
);

export const fetchAuthorizeSuccess = createAction('FETCH_AUTHORIZE_SUCCESS');
export const onAuthorizeSuccess = (data) => (dispatch) => {
    StorageApi.setToken(data.access_token);
    dispatch(fetchAuthorizeSuccess(data.access_token));
};

export const authorize = createAction('FETCH_AUTHORIZE',
    () => Api.authorize()
);

//- State
const initialState = {
    isAuthorised: !!StorageApi.getToken(),
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_AUTHORIZE: (state) => {
        return {...state, isAuthorised: false, error: null, fetching: true};
    },
    FETCH_AUTHORIZE_SUCCESS: (state, action) => {
        return {...state, isAuthorised: !!action.payload, error: null, fetching: false};
    },
    LOGOUT: (state) => {
        return {...state, isAuthorised: false, error: null};
    }

}, initialState);