import { createAction, handleActions } from 'redux-actions';

export const MODES = {
    MAIN: 'main',
    TRAINING: 'training',
    DEPLOY: 'deploy'
};

//- Actions
export const setMainMode = createAction('MAIN_MODE_SET');
export const setTrainingMode = createAction('TRAINING_MODE_SET');
export const setDeployMode = createAction('DEPLOY_MODE_SET');

//- State
const initialState = {
    mode: MODES.MAIN,
};

//- Reducers
export default handleActions({

    MAIN_MODE_SET: (state) => {
        return { ...state, mode: MODES.MAIN };
    },
    TRAINING_MODE_SET: (state) => {
        return { ...state, mode: MODES.TRAINING };
    },
    DEPLOY_MODE_SET: (state) => {
        return { ...state, mode: MODES.DEPLOY };
    },

}, initialState);

//- Selectors
export const getModeData = state => state.mode;

export const getMode = state => getModeData(state).mode;
