import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import _ from 'lodash';
import {logout} from "../login/login";

//- Actions
export const fetchGetChartCollectionsFailed = createAction('FETCH_GET_CHART_COLLECTIONS_FAILED');
export const fetchGetChartCollectionsSuccess = createAction('FETCH_GET_CHART_COLLECTIONS_SUCCESS');
export const fetchGetChartCollections = createAction(
    'FETCH_GET_CHART_COLLECTIONS',
    () => Api.getChartCollections(),
    () => ({
        apiCall: true,
        onSuccess: fetchGetChartCollectionsSuccess,
        onFail: fetchGetChartCollectionsFailed,
        onUnauthorized: logout
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_CHART_COLLECTIONS: (state) => {
        return {...state, data: null, error: null, fetching: true};
    },
    FETCH_GET_CHART_COLLECTIONS_FAILED: (state, action) => {
        return {...state, data: null, error: action.payload, fetching: false};
    },
    FETCH_GET_CHART_COLLECTIONS_SUCCESS: (state, action) => {
        return {...state, data: action.payload.chart_collections, error: null, fetching: false};
    }

}, initialState);

//- Selectors
export const getChartCollections = state => state.chartCollections;

export const getChartCollectionsOptions = state => {
    let data = getChartCollections(state).data;

    if (!data || data.length === 0) {
        return null;
    }

    return _.map(data, collection => ({
        value: collection.id,
        label: collection.name
    }));
};

export const loadingCollections = state => getChartCollections(state).fetching;
