import React, {Component} from 'react';
import './HeatmapComponent.css';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Utils} from "../../utils/utils";
import HeatMap from 'react-heatmap-grid';
import {Redirect} from "react-router-dom";
import ModelWithChart from "./modelWithChart/ModelWithChart.container";
import {MODES} from "../../reducers/mode/mode";

const OPACITY_BY_VALUE = [0, 0.3, 0.6, 1];
const LEGEND_LABELS = ['3+', '2', '1', '0'];
const MAX_VALUE = 3;

class HeatmapComponent extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isNil(this.props.heatmap) && !this.props.fetching) {
            this.props.onRedirect();
        }
    }


    getHeatmapMatrix = () => {
        let matrix = [];
        this.props.stocks.forEach(stock => {
            let row = [];
            this.props.intervals.forEach(interval => {
                row.push(this.props.heatmap[stock][interval]);
            });
            matrix.push(row);
        });
        return matrix;
    };

    getIntervals = () => {
        return this.props.intervals.map(interval => Utils.formatHeatmapInterval(interval));
    };

    getCellTitle = (value) => {
        return `${value} pattern${value === 1 ? '' : 's'}`;
    };

    render() {
        if ((_.isNil(this.props.heatmap) && !this.props.fetching) || this.props.mode === MODES.MAIN) {
            return <Redirect to='/chart'/>;
        }

        if (_.isNil(this.props.heatmap)) {
            return null;
        }

        return (
            <div className="heatmap-component">
                <div className='heatmap'>
                    <div className='heatmap-container'>
                        <HeatMap
                            yLabels={this.props.stocks}
                            xLabels={this.getIntervals()}
                            yLabelWidth={100}
                            data={this.getHeatmapMatrix()}
                            cellStyle={(background, value) => ({
                                background: this.getColorByValue(value),
                                color: "#000",
                                cursor: 'pointer',
                                border: '1px solid #e0e3eb',
                                margin: '0'
                            })}
                            title={this.getCellTitle}
                            onClick={this.handleCellClick}
                        />
                    </div>
                    <div className='heatmap-legend'>
                        {this.renderLegend()}
                    </div>
                </div>
                <ModelWithChart/>
            </div>
        );
    }

    renderLegend = () => {
        return _.map(LEGEND_LABELS, (label, index) => (
            <div className='row' key={'row' + index}>
                <div className='label'>{label}</div>
                <div className='cell'
                     style={{background: `rgb(0, 151, 230,${OPACITY_BY_VALUE[OPACITY_BY_VALUE.length - index - 1]})`}}/>
            </div>
        ))
    };

    getColorByValue = (value) => {
        return `rgb(0, 151, 230, ${OPACITY_BY_VALUE[value > 3 ? MAX_VALUE : value]})`
    };

    handleCellClick = (x, y) => {
        let interval = this.props.intervals[x];
        let stock = this.props.stocks[y];
        this.props.onGetChart(stock, interval);
    }
}

HeatmapComponent.propTypes = {
    heatmap: PropTypes.object,
    stocks: PropTypes.array,
    intervals: PropTypes.array,
    fetching: PropTypes.bool,
    mode: PropTypes.string,
    onRedirect: PropTypes.func.isRequired,
    onGetChart: PropTypes.func.isRequired,
};

export default HeatmapComponent;
