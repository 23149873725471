import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {ChartApi} from "../../api/chartApi";
import './PreviewPanel.css';
import {Utils} from "../../utils/utils";

const DISABLED_FEATURES = ['header_widget', 'legend_widget', 'timeframes_toolbar', 'chart_scroll', 'chart_zoom', 'left_toolbar', 'control_bar'];
const PATTERNS = {
    DETECTED: 'detectedPatterns',
    SELECTED: 'patterns'
};
const SHAPE_OVERRIDES = {
    [PATTERNS.DETECTED]: {
        'backgroundColor': '#3c65a7',
        'color': '#3c65a7'
    },
    [PATTERNS.SELECTED]: {
        'backgroundColor': '#39ba1f',
        'color': '#39ba1f'
    }
};
const VISIBLE_RANGE_OVERRIDES = {
    'linetooldaterange.textcolor': 'transparent',
    'linetooldaterange.labelBackgroundColor': 'transparent'
};
const DEFAULT_BARS_INTERVAL = 2;

class PreviewPanel extends Component {
    constructor(props) {
        super(props);

        this.widget = null;

        this.state = {
            changedMode: false,
            rangeId: null,
            position: null,
            detectedPatterns: [],
            priceRange: null,
            patterns: [],
            chartReady: false,
            interval: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mode !== prevProps.mode) {
            this.setState({changedMode: true});
        }

        if (!_.isEqual(this.props.chart, prevProps.chart) && this.props.chart) {
            if (!this.widget || this.state.changedMode) {
                this.setState({changedMode: false, chartReady: false});
                this.setupWidget();
            } else {
                this.removeRange();
                this.widget.setSymbol(this.props.chart.id, this.getResolution(), () => {
                });
            }
        }

        if (!prevProps.bars && this.props.bars) {
            let max = _.maxBy(this.props.bars, bar => bar.high).high;
            let min = _.minBy(this.props.bars, bar => bar.low).low;
            this.setState({
                priceRange: {
                    from: min - (max - min) / 2,
                    to: max + (max - min) / 2
                }
            })
        }

        if (prevProps.bars && !this.props.bars) {
            this.setState({priceRange: null})
        }

        if ((!_.isEqual(this.props.interval, prevProps.interval) || _.isNil(this.state.rangeId)) &&
            !_.isNil(this.props.interval) && !_.isNil(this.state.priceRange) && this.state.chartReady) {
            this.drawRange();
        }

        if ((!_.isEqual(prevProps.detectedPatterns, this.props.detectedPatterns) ||
            (_.isEmpty(this.props.detectedPatterns) && !_.isEmpty(this.state.detectedPatterns))) && this.state.chartReady) {
            this.removePatterns(PATTERNS.DETECTED);
            if (!_.isNil(this.props.detectedPatterns)) {
                this.drawPatterns(PATTERNS.DETECTED);
            }
        }

        if (!_.isEqual(prevProps.patterns, this.props.patterns)) {
            this.removePatterns(PATTERNS.SELECTED);
            if (!_.isNil(this.props.patterns)) {
                this.drawPatterns(PATTERNS.SELECTED);
            }
        }
    }

    removeRange = () => {
        if (this.state.rangeId) {
            let chart = this.widget.chart();
            chart.removeEntity(this.state.rangeId);
            this.setState({rangeId: null});
        }
    };

    removePatterns = (field) => {
        let chart = this.widget.chart();
        _.forEach(this.state[field], patternId => {
            chart.removeEntity(patternId);
        });
        this.setState({[field]: []});
    };

    drawPatterns = (field) => {
        let chart = this.widget.chart();
        let patterns = [];
        _.forEach(this.props[field], points => {
            let id = chart.createMultipointShape(points, {
                shape: "rectangle",
                lock: true,
                zOrder: 'top',
                disableSelection: true,
                overrides: SHAPE_OVERRIDES[field],
            });
            patterns.push(id);
        });

        this.setState({[field]: patterns});
    };

    extendInterval = (start, end, extendTo) => {
        while (end - start < extendTo) {
            if (start > 0) {
                start--;
            }
            if (end < this.props.bars.length - 1 && end - start < extendTo) {
                end++;
            }
        }
        return [start, end];
    };

    getTimeRange = (range) => {
        if (!this.props.bars) {
            return null;
        }

        let start = _.findIndex(this.props.bars, bar => bar.time / 1000 > range.from);
        let end = _.findIndex(this.props.bars, bar => bar.time / 1000 > range.to);

        start = start >= 0 ? start : this.props.bars.length - 1;
        end = end >= 0 ? end : this.props.bars.length - 1;

        [start, end] = this.extendInterval(start, end, DEFAULT_BARS_INTERVAL);

        return {
            from: this.props.bars[start].time / 1000,
            to: this.props.bars[end].time / 1000
        }
    };

    drawRange = () => {
        let chart = this.widget.chart();

        let interval = this.getTimeRange(this.props.interval);
        if (!interval) {
            return;
        }

        let points = [
            {
                price: this.state.priceRange.from,
                time: interval.from
            },
            {
                price: this.state.priceRange.to,
                time: interval.to
            }
        ];

        if (this.state.rangeId) {
            chart.removeEntity(this.state.rangeId);
        }
        let id = chart.createMultipointShape(points, {
            shape: "date_range",
            lock: true,
            disableSelection: true,
            zOrder: 'bottom'
        });
        this.setState({rangeId: id});
    };

    getBars = () => {
        return this.props.bars;
    };

    getResolution = () => {
        let interval = this.props.bars[1].time - this.props.bars[0].time;
        return Utils.getResolutionForInterval(interval);
    };

    setupWidget = () => {
        const widgetOptions = {
            symbol: this.props.chart.name,
            datafeed: {
                onReady: ChartApi.onReady,
                searchSymbols: ChartApi.searchSymbols,
                resolveSymbol: ChartApi.resolveSymbol(this.getResolution),
                getBars: ChartApi.getBars(this.getBars),
                subscribeBars: ChartApi.subscribeBars,
                unsubscribeBars: ChartApi.unsubscribeBars,
            },
            container_id: 'preview_panel',
            library_path: '/charting_library/',
            autosize: true,
            disabled_features: DISABLED_FEATURES,
            overrides: VISIBLE_RANGE_OVERRIDES
        };
        const widget = new window.TradingView.widget(widgetOptions);
        this.widget = widget;
        widget.onChartReady(() => {
            this.setState({chartReady: true});

            widget.chart().setVisibleRange({
                from: this.props.bars[0].time / 1000,
                to: this.props.bars[this.props.bars.length - 1].time / 1000
            });
            widget.subscribe('mouse_up', () => {
                this.handleMouseClick();
            });
            widget.chart().crossHairMoved(({time}) => {
                if (!_.isNull(time)) {
                    this.setState({position: time});
                }
            });
            widget.chart().onVisibleRangeChanged().subscribe(null, (range) => {
                let fullRange = {
                    from: this.props.bars[0].time / 1000,
                    to: this.props.bars[this.props.bars.length - 1].time / 1000
                };
                if (range.from !== fullRange.from || range.to !== fullRange.to) {
                    widget.chart().setVisibleRange(fullRange);
                }
            })
        });
    };

    handleMouseClick = () => {
        let index = _.findIndex(this.props.bars, bar => bar.time / 1000 > this.state.position);
        if (index === -1) {
            return;
        }

        let start = _.findIndex(this.props.bars, bar => bar.time / 1000 > this.props.interval.from);
        let end = _.findIndex(this.props.bars, bar => bar.time / 1000 > this.props.interval.to);

        start = start === -1 ? this.props.bars.length - 1 : start;
        end = end === -1 ? this.props.bars.length - 1 : end;

        let distance = end - start;
        distance = distance < DEFAULT_BARS_INTERVAL ? DEFAULT_BARS_INTERVAL : distance;
        start = index;
        end = index;

        [start, end] = this.extendInterval(start, end, distance);

        let interval = {
            from: this.props.bars[start].time / 1000,
            to: this.props.bars[end].time / 1000
        };
        this.props.setVisibleInterval(interval);
    };

    render() {
        return (
            <div className='preview-panel'>
                <div id='preview_panel'/>
            </div>
        );
    }
}

PreviewPanel.propTypes = {
    chart: PropTypes.object,
    bars: PropTypes.array,
    interval: PropTypes.object,
    detectedPatterns: PropTypes.array,
    patterns: PropTypes.array,
    setVisibleInterval: PropTypes.func.isRequired,
};


export default PreviewPanel;