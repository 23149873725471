import React, {Component} from 'react';
import './SidePanel.css';
import PropTypes from 'prop-types';
import {MODES} from "../../reducers/mode/mode";
import MainPanel from "./mainPanel/MainPanel.container";
import TrainingPanel from "./trainingPanel/TrainingPanel.container";

class SidePanel extends Component {
    render() {
        return (
            <div className="side-panel">
                {this.renderContent()}
            </div>
        );
    }

    renderContent = () => {
        switch (this.props.mode) {
            case MODES.MAIN:
                return <MainPanel/>;
            case MODES.TRAINING:
                return <TrainingPanel/>;
            default:
                return;
        }
    }
}

SidePanel.propTypes = {
    mode: PropTypes.string.isRequired
};

export default SidePanel;
