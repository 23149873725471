import {createAction, handleActions} from 'redux-actions';
import _ from "lodash";
import {getPreviewChartBars} from "../charts/previewChart";
import {getChartId} from "../charts/chart";
import {Utils} from "../../utils/utils";

//- Actions
export const clearPatterns = createAction('PATTERNS_CLEAR');

export const savePatterns = createAction('PATTERNS_SAVE', (chartId, patterns) => ({chartId, patterns}));

export const addShape = (chartId, shapeToAdd) => (dispatch, getState) => {
    let data = getPatternsData(getState());
    let dataByChart = data[chartId];
    if (!dataByChart) {
        dataByChart = [];
    }
    let path = null;
    _.map(dataByChart, (pattern, patternIndex) => {
        _.map(pattern.shapes, (shape, shapeIndex) => {
            if (shape.id === shapeToAdd.id) {
                path = [patternIndex, 'shapes', shapeIndex];
            }
        });
    });
    if (path) {
        _.set(dataByChart, path, shapeToAdd);
    } else {
        dataByChart.push({
            pattern_type: shapeToAdd.shape_type,
            shapes: [shapeToAdd]
        });
    }
    dispatch(savePatterns(chartId, dataByChart));
};

export const removeShape = (chartId, shapeId) => (dispatch, getState) => {
    let data = getPatternsData(getState());
    let dataByChart = data[chartId];
    if (!dataByChart) {
        dataByChart = [];
    }
    let path = [];
    _.map(dataByChart, (pattern, patternIndex) => {
        _.map(pattern.shapes, (shape, shapeIndex) => {
            if (shape.id === shapeId) {
                path = [patternIndex, 'shapes', shapeIndex];
            }
        });
    });
    if (path.length !== 0) {
        _.unset(dataByChart, path);
        let shapes = _.get(dataByChart, path.slice(0, -1)).filter(shape => shape);
        if (shapes.length === 0) {
            _.unset(dataByChart, path.slice(0, -2));
            dataByChart = dataByChart.filter(pattern => pattern);
        } else {
            dataByChart[path[0]] = shapes;
        }
    }
    dispatch(savePatterns(chartId, dataByChart));
};

//- State
const initialState = {
    data: {},
};

//- Reducers
export default handleActions({

    PATTERNS_CLEAR: (state) => {
        return {...state, data: {}};
    },
    PATTERNS_SAVE: (state, action) => {
        let data = {...state.data};
        if (action.payload.patterns) {
            if (action.payload.patterns.length > 0) {
                data[action.payload.chartId] = action.payload.patterns;
            } else {
                data = _.omit(data, action.payload.chartId);
            }
        }
        return {...state, data};
    }

}, initialState);

//- Selectors
export const getPatterns = state => state.patterns;

export const getPatternsData = state => getPatterns(state).data;

export const hasPatterns = state => !_.isEmpty(getPatternsData(state));

export const getPatternsNumber = state => {
    let data = getPatternsData(state);
    let number = 0;

    _.map(data, patternsByChart => {
        number += patternsByChart.length;
    });

    return number;
};

export const getPreviewPatterns = state => {
    let chartId = getChartId(state);
    let patterns = getPatternsData(state)[chartId];
    let bars = getPreviewChartBars(state);

    if (_.isNil(chartId) || _.isNil(bars) || _.isNil(patterns)) {
        return null;
    }
    patterns = _.map(patterns, pattern => pattern.shapes);

    return Utils.convertPatternsForBars(patterns, bars);
};