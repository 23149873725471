import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import _ from 'lodash';
import {logout} from "../login/login";
import {getChartCollectionData, getSelectedChartForDeployment} from "../charts/chartCollection";
import {Utils} from "../../utils/utils";

//- Actions
export const fetchDetectPatternsForCollectionFailed = createAction('FETCH_DETECT_PATTERN_FOR_COLLECTION_FAILED');
export const fetchDetectPatternsForCollectionSuccess = createAction('FETCH_DETECT_PATTERN_FOR_COLLECTION_SUCCESS');
export const fetchDetectPatternsForCollection = createAction(
    'FETCH_DETECT_PATTERN_FOR_COLLECTION',
    (modelId, collectionId) => Api.detectPatternsOnCollection(modelId, collectionId),
    () => ({
        apiCall: true,
        onSuccess: fetchDetectPatternsForCollectionSuccess,
        onFail: fetchDetectPatternsForCollectionFailed,
        onUnauthorized: logout
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_DETECT_PATTERN_FOR_COLLECTION: (state) => {
        return {...state, data: null, error: null, fetching: true};
    },
    FETCH_DETECT_PATTERN_FOR_COLLECTION_FAILED: (state, action) => {
        return {...state, data: null, error: action.payload, fetching: false};
    },
    FETCH_DETECT_PATTERN_FOR_COLLECTION_SUCCESS: (state, action) => {
        return {...state, data: action.payload.data, error: null, fetching: false};
    }

}, initialState);

//- Selectors
export const getCollectionPatterns = state => state.collectionPatterns;

export const getCollectionPatternsData = state => getCollectionPatterns(state).data;

export const getCollectionPatternsCountData = state => {
    let collectionData = getChartCollectionData(state);
    let patternsData = getCollectionPatternsData(state);

    if (_.isNil(collectionData) || _.isNil(patternsData)) {
        return null;
    }

    return _.chain(collectionData)
        .groupBy(chart => chart.stock_id)
        .mapValues(charts => _.groupBy(charts, chart => chart.interval))
        .mapValues(charts => _.mapValues(charts, chartsByInterval => {
                let id = chartsByInterval[0].id;
                return _.find(patternsData, patterns => patterns.chart_id === id).count
            })
        )
        .value();
};

export const detectingPatternsForCollection = state => getCollectionPatterns(state).fetching;

export const getPatternsForSelectedChart = state => {
    let chart = getSelectedChartForDeployment(state);
    let data = getCollectionPatternsData(state);

    if (_.isNil(chart) || _.isNil(data)) {
        return null;
    }
    let patterns = _.find(data, patterns => patterns.chart_id === chart.id).patterns;

    return _.chain(patterns)
        .map(pattern => pattern.shapes)
        .map(shapes => _.map(shapes, shape => ({
            ...shape,
            points: _.map(shape.points, point => ({
                price: point.price,
                time: Utils.convertDatetimeToTimestamp(point.datetime) / 1000
            }))
        })))
        .value()
};
