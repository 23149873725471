import {connect} from 'react-redux';
import WarningModal from "./WarningModal";
import {closeModal, DETECTED_PATTERNS_WARNING_MODAL, getActiveModal} from "../../../reducers/modal/modal";

const LABEL = 'No patterns have been detected';

const mapStateToProps = state => {
    return {
        isOpen: getActiveModal(state) === DETECTED_PATTERNS_WARNING_MODAL,
        label: LABEL
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(closeModal());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);