import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import {getChartId} from "../charts/chart";
import _ from 'lodash';
import {logout} from "../login/login";
import {getConfidence, getTemporaryModel, setConfidence} from "../models/models";
import {Utils} from "../../utils/utils";
import {getPreviewChartBars} from "../charts/previewChart";
import {DETECTED_PATTERNS_WARNING_MODAL, openModal} from "../modal/modal";

export const MAX_PATTERNS_NUMBER = 100;

//- Actions
export const fetchDetectPatternsSuccess = createAction('FETCH_DETECT_PATTERNS_SUCCESS');
export const onDetectPatternsSuccess = (data) => (dispatch, getState) => {
    let patterns = data.patterns;
    if (patterns.length === 0) {
        dispatch(openModal(DETECTED_PATTERNS_WARNING_MODAL));
    }

    let confidence = getConfidence(getState());
    let count = _.countBy(patterns, pattern => pattern.confidence >= confidence);

    let payload = {
        patterns: patterns.sort((a, b) => b.confidence - a.confidence),
        sliderValue: count.hasOwnProperty('true') ? count.true : 1
    };
    dispatch(fetchDetectPatternsSuccess(payload));
};

export const fetchDetectPatternsFailed = createAction('FETCH_DETECT_PATTERNS_FAILED');
export const fetchDetectPatterns = createAction(
    'FETCH_DETECT_PATTERNS',
    (modelId, chartId, useConfidence, maxNumber) => Api.detectPatterns(modelId, chartId, useConfidence, maxNumber),
    () => ({
        apiCall: true,
        onSuccess: onDetectPatternsSuccess,
        onFail: fetchDetectPatternsFailed,
        onUnauthorized: logout
    })
);

export const detectPatterns = (modelId) => (dispatch, getState) => {
    let chartId = getChartId(getState());

    if (chartId) {
        dispatch(fetchDetectPatterns(modelId, chartId, true));
    }
};

export const clearDetectedPatterns = createAction('DETECTED_PATTERNS_CLEAR');

export const detectPatternsForCurrentModel = () => (dispatch, getState) => {
    let chartId = getChartId(getState());
    let modelId = getTemporaryModel(getState());

    if (chartId && modelId) {
        dispatch(fetchDetectPatterns(modelId, chartId, false, MAX_PATTERNS_NUMBER));
    }
};

export const onSetSliderValue = (value) => (dispatch, getState) => {
    let confidence = getConfidenceByIndex(getState(), value - 1);

    if (confidence) {
        dispatch(setConfidence(confidence));
    }

    dispatch(setSliderValue(value));
};

export const setSliderValue = createAction('SLIDER_VALUE_SET', value => value);
export const setPatternIds = createAction('PATTERN_IDS_SET', ids => ids);

//- State
const initialState = {
    data: null,
    fetching: false,
    error: null,
    sliderValue: null,
    patternIds: []
};

//- Reducers
export default handleActions({

    FETCH_DETECT_PATTERNS: (state) => {
        return {...state, data: null, fetching: true, error: null};
    },
    FETCH_DETECT_PATTERNS_SUCCESS: (state, action) => {
        return {
            ...state,
            data: action.payload.patterns,
            sliderValue: action.payload.sliderValue,
            fetching: false,
            error: null
        };
    },
    FETCH_DETECT_PATTERNS_FAILED: (state, action) => {
        return {...state, fetching: false, error: action.payload};
    },
    DETECTED_PATTERNS_CLEAR: (state) => {
        return {...state, data: null};
    },
    SLIDER_VALUE_SET: (state, action) => {
        return {...state, sliderValue: action.payload};
    },
    PATTERN_IDS_SET: (state, action) => {
        return {...state, patternIds: action.payload};
    }

}, initialState);

//- Selectors
export const getPatternsDetection = state => state.patternsDetection;

export const getMaxSliderValue = state => {
    let data = getPatternsDetection(state).data;

    return _.isNil(data) ? null : data.length;
};

export const getSliderValue = state => {
    return getPatternsDetection(state).sliderValue;
};

export const getDetectedPatterns = state => {
    let data = getPatternsDetection(state).data;

    if (_.isNil(data)) {
        return null;
    }

    data = data.slice(0, getSliderValue(state));

    return _.chain(data)
        .map(pattern => pattern.shapes)
        .map(shapes => _.map(shapes, shape => ({
            ...shape,
            points: _.map(shape.points, point => ({
                price: point.price,
                time: Utils.convertDatetimeToTimestamp(point.datetime) / 1000
            }))
        })))
        .value();
};

export const getPreviewDetectedPatterns = state => {
    let patterns = getDetectedPatterns(state);
    let bars = getPreviewChartBars(state);

    if (_.isNil(patterns) || _.isNil(bars)) {
        return null;
    }

    return Utils.convertPatternsForBars(patterns, bars);
};

export const detectingPatterns = state => getPatternsDetection(state).fetching;

export const hasDetectedPatterns = state => {
    let data = getPatternsDetection(state).data;

    return !_.isNil(data) && data.length !== 0;
};

export const getConfidenceByIndex = (state, index) => {
    let data = getPatternsDetection(state).data;

    return !_.isNil(data) ? data[index].confidence : null;
};

export const getPatternIds = state => getPatternsDetection(state).patternIds;

export const getDetectedPatternsNumber = state => {
    let data = getPatternsDetection(state).data;

    return _.isNil(data) ? null : data.length;
};