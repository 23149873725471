import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.container';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {rootEpic, rootReducer} from "./reducers";
import {createEpicMiddleware} from "redux-observable";
import thunk from "redux-thunk";
import Amplify, {Auth} from 'aws-amplify';
import awsconfig from "./aws-config/awsconfig";
import {BrowserRouter as Router, withRouter} from 'react-router-dom';
import {authorize} from "./reducers/login/login";

const epicMiddleware = createEpicMiddleware();

const store = createStore(rootReducer, applyMiddleware(thunk, epicMiddleware));

epicMiddleware.run(rootEpic);

awsconfig.Auth.oauth.redirectSignIn = process.env.REACT_APP_REDIRECT_URL + '/auth';
awsconfig.Auth.oauth.redirectSignOut = process.env.REACT_APP_REDIRECT_URL;

Amplify.configure(awsconfig);

Auth.currentSession()
    .catch(() => {
        if (window.location.pathname === '/') {
            store.dispatch(authorize());
        }
    });

let WidgetContainer = withRouter(App);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <WidgetContainer/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
