import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import CustomButton from "../../../common/button/CustomButton";
import './DeployModelModal.css';
import _ from 'lodash';
import CustomSelect from "../../../common/select/CustomSelect";
import {Utils} from "../../../../utils/utils";
import {Redirect} from "react-router-dom";

class DeployModelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCollection: '',
            error: false,
            redirect: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({selectedCollection: '', error: false});
        }

        if (!prevState.redirect && this.state.redirect) {
            this.props.onDeploy(this.props.data, this.state.selectedCollection);
        }
    }

    render() {
        if (_.isNil(this.props.collections)) {
            return null;
        }

        if (this.state.redirect) {
            return <Redirect to="/heatmap"/>;
        }

        return (
            <Dialog classes={{paper: 'deploy-model-modal '}} open={this.props.isOpen} onClose={this.props.onClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Select chart collection for model deployment</DialogTitle>
                <DialogContent classes={{root: 'deploy-model-modal-content'}}>
                    <CustomSelect
                        error={this.state.error}
                        variant='outlined'
                        className={'collection-select'}
                        displayEmpty
                        value={this.state.selectedCollection}
                        renderValue={this.renderValueForCollectionSelect}
                        onChange={this.handleSelectCollection}>
                        {Utils.convertOptions(this.props.collections)}
                    </CustomSelect>
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        variant="outlined"
                        onClick={this.props.onClose}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                        variant="outlined"
                        onClick={this.handleDeploy}>
                        Deploy
                    </CustomButton>
                </DialogActions>
            </Dialog>
        );
    }

    renderValueForCollectionSelect = (value) => {
        if (_.isEmpty(value)) {
            return 'Select...';
        } else {
            return _.find(this.props.collections, collection => collection.value === value).label;
        }
    };

    handleSelectCollection = (e) => {
        this.setState({selectedCollection: e.target.value, error: false});
    };

    handleDeploy = () => {
        if (_.isEmpty(this.state.selectedCollection)) {
            this.setState({error: true});
        } else {
            this.props.onClose();
            this.setState({redirect: true});
        }
    };
}

DeployModelModal.propTypes = {
    data: PropTypes.string,
    collections: PropTypes.array,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDeploy: PropTypes.func.isRequired,
};

export default DeployModelModal;
