import {createAction, handleActions} from 'redux-actions';

export const PATTERNS_NUMBER_WARNING_MODAL = 'PATTERNS_NUMBER_WARNING_MODAL';
export const DETECTED_PATTERNS_WARNING_MODAL = 'DETECTED_PATTERNS_WARNING_MODAL';
export const CHANGE_MODEL_MODAL = 'CHANGE_MODEL_MODAL';
export const SAVE_MODEL_MODAL = 'SAVE_MODEL_MODAL';
export const DEPLOY_MODEL_MODAL = 'DEPLOY_MODEL_MODAL';
export const DEPLOYMENT_MODAL = 'DEPLOY_MODEL_MODAL';

//- Actions
export const openModal = createAction('MODEL_OPEN', modal => modal);
export const closeModal = createAction('MODEL_CLOSE');

//- State
const initialState = {
    activeModal: null,
};

//- Reducers
export default handleActions({

    MODEL_OPEN: (state, action) => {
        return {...state, activeModal: action.payload};
    },
    MODEL_CLOSE: (state) => {
        return {...state, activeModal: null};
    }

}, initialState);

//- Selectors
export const getModal = state => state.modal;

export const getActiveModal = state => getModal(state).activeModal;

