import React, {Component} from 'react';
import {CircularProgress} from '@material-ui/core';
import './CustomSpinner.css';

class CustomSpinner extends Component {
    render() {
        return (
            <div className='root'>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    classes={{root: 'static'}}
                    size={40}
                    thickness={5}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    classes={{root: 'dynamic'}}
                    size={40}
                    thickness={5}
                />
            </div>
        )
    }
}

export default CustomSpinner;

