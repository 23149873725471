import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogActions, TextField} from "@material-ui/core";
import CustomButton from "../../common/button/CustomButton";
import './SaveModelModal.css';
import _ from 'lodash';

export const ACTIONS = {
    SAVE: 'save',
    TRAIN_SAVE: 'train_save'
};

class SaveModelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            error: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.training && !this.props.training) {
            this.props.onClose();
        }

        if (prevProps.isOpen && !this.props.isOpen) {
            this.setState({name: '', error: false});
        }
    }

    render() {
        let label = '';
        let button_label = '';

        switch (this.props.action) {
            case ACTIONS.SAVE:
                label = 'Save your model';
                button_label = 'Save';
                break;
            case ACTIONS.TRAIN_SAVE:
                label = 'Train and save your model';
                button_label = 'Train and Save';
                break;
            default:
                break;
        }

        return (
            <Dialog classes={{paper: 'save-model-modal '}} open={this.props.isOpen} onClose={this.props.onClose}
                    aria-labelledby="form-dialog-title" onKeyDown={this.handleKeyDown}>
                <DialogTitle id="form-dialog-title">{label}</DialogTitle>
                    <DialogContent classes={{root: 'save-model-modal-content'}}>
                        <TextField
                            classes={{root: 'custom-input'}}
                            error={this.state.error}
                            helperText={this.state.error? "Please, fill out this field" : ''}
                            label="Model name"
                            value={this.state.name}
                            onChange={this.handleChange} />
                    </DialogContent>
                    <DialogActions>
                        <CustomButton
                            variant="outlined"
                            onClick={this.props.onClose}
                            disabled={this.props.training}>
                            Cancel
                        </CustomButton>
                        <CustomButton
                            variant="outlined"
                            onClick={this.handleDiscardChanges}
                            disabled={this.props.training}>
                            Discard changes
                        </CustomButton>
                        <CustomButton
                            variant="outlined"
                            onClick={this.handleSave}
                            disabled={this.props.training}>
                            {button_label}
                        </CustomButton>
                    </DialogActions>
            </Dialog>
        );
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSave();
        }
    };

    handleChange = (e) => {
        this.setState({name: e.target.value, error: false});
    };

    handleSave = () => {
        if (_.isEmpty(this.state.name)) {
            this.setState({error: true});
        } else {
            this.props.onSave(this.state.name);
        }
    };

    handleDiscardChanges = () => {
        this.props.onClose();
        this.props.onDiscardChanges();
    }
}

SaveModelModal.propTypes = {
    action: PropTypes.string,
    training: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDiscardChanges: PropTypes.func.isRequired,
};

export default SaveModelModal;
