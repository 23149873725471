import {connect} from 'react-redux';
import {onAuthorizeSuccess} from "./reducers/login/login";
import App from "./App";
import {loadingChart} from "./reducers/charts/chart";
import {trainingModel} from "./reducers/models/models";
import {detectingPatterns} from "./reducers/patterns/patternsDetection";
import {getMode, setMainMode} from "./reducers/mode/mode";
import {detectingPatternsForCollection} from "./reducers/patterns/collectionPatterns";
import {loadingChartCollection} from "./reducers/charts/chartCollection";

const mapStateToProps = state => {
    return {
        fetching: loadingChart(state) ||
            trainingModel(state) ||
            detectingPatterns(state) ||
            detectingPatternsForCollection(state) ||
            loadingChartCollection(state),
        mode: getMode(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthorizeSuccess: (data) => {
            dispatch(onAuthorizeSuccess(data))
        },
        setMainMode: () => dispatch(setMainMode())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);