import {connect} from 'react-redux';
import WarningModal from "./WarningModal";
import {closeModal, getActiveModal, PATTERNS_NUMBER_WARNING_MODAL} from "../../../reducers/modal/modal";

const LABEL = 'Please, select at least 3 patterns';

const mapStateToProps = state => {
    return {
        isOpen: getActiveModal(state) === PATTERNS_NUMBER_WARNING_MODAL,
        label: LABEL
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(closeModal());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);