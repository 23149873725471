import {connect} from 'react-redux';
import PreviewPanel from "./PreviewPanel";
import {
    getInterval,
    getPreviewChartBars,
    getPreviewChartData,
    setVisibleInterval
} from "../../reducers/charts/previewChart";
import {getPreviewDetectedPatterns} from "../../reducers/patterns/patternsDetection";
import {getPreviewPatterns} from "../../reducers/patterns/patterns";

const mapStateToProps = state => {
    return {
        chart: getPreviewChartData(state),
        bars: getPreviewChartBars(state),
        interval: getInterval(state),
        detectedPatterns: getPreviewDetectedPatterns(state),
        patterns: getPreviewPatterns(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setVisibleInterval: (interval) => dispatch(setVisibleInterval(interval))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPanel);