const TOKEN_ID = "saffronblue_user_token";

export class StorageApi {
    static setToken(token) {
        if (token) {
            localStorage.setItem(TOKEN_ID, token);
        }
    }

    static removeToken() {
        localStorage.removeItem(TOKEN_ID);
    }

    static getToken() {
        return localStorage.getItem(TOKEN_ID);
    }
}