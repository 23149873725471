import React, {Component} from 'react';
import './App.css';
import ChartComponent from "./components/chartComponent/ChartComponent.container";
import {Utils} from "./utils/utils";
import PropTypes from 'prop-types';
import {Redirect, Route, Switch} from "react-router-dom";
import Toolbar from "./components/toolbar/Toolbar.container";
import SidePanel from "./components/sidePanel/SidePanel.container";
import CustomSpinner from "./components/common/spinner/CustomSpinner";
import PreviewPanel from "./components/previewPanel/PreviewPanel.container";
import {MODES} from "./reducers/mode/mode";
import HeatmapComponent from "./components/heatmapComponent/HeatmapComponent.container";

class App extends Component {
    componentDidMount() {
        if (this.props.location.pathname === '/auth') {
            this.props.onAuthorizeSuccess(Utils.getParamsFromLocationHash(this.props.location.hash));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mode === MODES.DEPLOY && this.props.location.pathname === '/chart') {
            this.props.setMainMode();
        }
    }

    render() {
        if (window.location.pathname === '/auth') {
            return <Redirect to="/chart"/>;
        }

        return (
            <div className={"App " + (this.props.mode === MODES.DEPLOY ? 'deploy_mode' : '')}>
                <Toolbar/>
                <Switch>
                    <Route path="/chart" render={this.renderByView("chart")}/>
                    <Route path="/heatmap" render={this.renderByView("heatmap")}/>
                </Switch>
                <div
                    className={'loading-fade ' + (this.props.fetching ? 'visible ' : '') + (this.props.mode === MODES.DEPLOY ? 'fullscreen' : '')}>
                    <CustomSpinner/>
                </div>
            </div>
        );
    }

    renderByView = (view) => () => {
        switch (view) {
            case "chart":
                return [
                    <ChartComponent key='chart-component'/>,
                    <PreviewPanel key='preview-panel'/>,
                    <SidePanel key='side-panel'/>
                ];
            case "heatmap":
                return <HeatmapComponent/>;
            default:
                return;
        }
    }
}

App.propTypes = {
    fetching: PropTypes.bool,
    mode: PropTypes.string,
    onAuthorizeSuccess: PropTypes.func.isRequired,
    setMainMode: PropTypes.func.isRequired,
};

export default App;
