import { connect } from 'react-redux';
import DeployModelModal from "./DeployModelModal";
import {closeModal, DEPLOY_MODEL_MODAL, getActiveModal} from "../../../../reducers/modal/modal";
import {getChartCollectionsOptions, loadingCollections} from "../../../../reducers/charts/chartCollections";
import {setDeployMode} from "../../../../reducers/mode/mode";
import {fetchDetectPatternsForCollection} from "../../../../reducers/patterns/collectionPatterns";
import {fetchGetChartCollection} from "../../../../reducers/charts/chartCollection";

const mapStateToProps = state => {
    return {
        isOpen: getActiveModal(state) === DEPLOY_MODEL_MODAL,
        collections: getChartCollectionsOptions(state),
        loading: loadingCollections(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDeploy: (modelId, collectionId) => {
            dispatch(setDeployMode());
            dispatch(fetchGetChartCollection(collectionId));
            dispatch(fetchDetectPatternsForCollection(modelId, collectionId));
        },
        onClose: () => {
            dispatch(closeModal());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeployModelModal);