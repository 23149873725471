import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import _ from 'lodash';
import {logout} from "../login/login";
import {clearPatterns} from "../patterns/patterns";

//- Actions
export const fetchGetChartsFailed = createAction('FETCH_GET_CHARTS_FAILED');
export const fetchGetChartsSuccess = createAction('FETCH_GET_CHARTS_SUCCESS');
export const onGetChartsSuccess = (data) => (dispatch) => {
    dispatch(fetchGetChartsSuccess(data));
    dispatch(clearPatterns());
};
export const fetchGetCharts = createAction(
    'FETCH_GET_CHARTS',
    () => Api.getCharts(),
    () => ({
        apiCall: true,
        onSuccess: onGetChartsSuccess,
        onFail: fetchGetChartsFailed,
        onUnauthorized: logout
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_CHARTS: (state) => {
        return {...state, data: null, error: null, fetching: true};
    },
    FETCH_GET_CHARTS_FAILED: (state, action) => {
        return {...state, data: null, error: action.payload, fetching: false};
    },
    FETCH_GET_CHARTS_SUCCESS: (state, action) => {
        return {...state, data: action.payload.charts, error: null, fetching: false};
    }

}, initialState);

//- Selectors
export const getCharts = state => state.charts;

export const getChartsDataOptions = state => {
    let data = getCharts(state).data;

    if (!data || data.length === 0) {
        return null;
    }

    return _.map(data, chart => ({
        value: chart.id,
        label: chart.name
    }));
};

export const loadingCharts = state => getCharts(state).fetching;
