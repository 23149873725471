import {withStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'black',
            color: '#ececec',
            fontFamily: 'monospace',
            '& fieldset': {
                border: 'none'
            },
            '&:hover fieldset': {
                border: 'none'
            },
            '&.Mui-focused fieldset': {
                border: 'none'
            }
        },
    }
};


export default withStyles(styles)(TextField);
