import React, {Component} from 'react';
import './MainPanel.css';
import PropTypes from 'prop-types';
import {Grid, IconButton, Collapse} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import _ from "lodash";
import CustomSelect from "../../common/select/CustomSelect";
import CustomButton from "../../common/button/CustomButton";
import ChangeModelModal from "./changeModelModal/ChangeModelModal.container";
import DeployModelModal from "./deployModelModal/DeployModelModal.container";
import {Utils} from "../../../utils/utils";
import DetectedPatternsWarningModal from "../../common/warningModal/DetectedPatternsWarningModal.container";

class MainPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedChart: '',
            selectedModel: ''
        };

        this.props.onMount();
    }

    render() {
        return (
            <div className="main-panel">
                {this.renderChartForm()}
                {this.renderModelForm()}
                <ChangeModelModal data={_.find(this.props.models, model => model.value === this.state.selectedModel)}/>
                <DeployModelModal data={this.state.selectedModel}/>
                <DetectedPatternsWarningModal/>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isEmpty(this.state.selectedChart) && this.props.charts && this.props.charts.length !== 0) {
            this.setState({selectedChart: this.props.charts[0].value});
            this.props.onSelectChart(this.props.charts[0].value);
        }

        if (!_.isEqual(prevProps.models, this.props.models) && this.props.models && this.state.selectedModel) {
            this.setState({selectedModel: this.props.models.find(model => model.value === this.state.selectedModel.value).value});
        }
    }

    renderChartForm = () => {
        //TODO: remove 'disabled={true}' when this functionality will be implemented
        return (
            <div className='form-container'>
                <Grid className='form-container' container spacing={2}>
                    <Grid item xs={12}>
                        Chart:
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <CustomSelect
                            className="custom-select"
                            variant='outlined'
                            value={this.state.selectedChart}
                            onChange={this.handleSelectChart}>
                            {Utils.convertOptions(this.props.charts)}
                        </CustomSelect>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton aria-label="delete" disabled={true}>
                            <PublishIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton aria-label="delete" disabled={true}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        );
    };

    handleSelectChart = (e) => {
        this.setState({selectedChart: e.target.value});
        this.props.onSelectChart(e.target.value);
    };

    renderModelForm = () => {
        let model = _.find(this.props.models, model => model.value === this.state.selectedModel);

        return (
            <div className='form-container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        Model:
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <CustomSelect
                            className="custom-select"
                            variant='outlined'
                            displayEmpty
                            value={this.state.selectedModel}
                            renderValue={this.renderValueForModelSelect}
                            onChange={this.handleSelectModel}>
                            {Utils.convertOptions(this.props.models)}
                        </CustomSelect>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton disabled={!model || model.common}
                                    onClick={this.handleEditModel}>
                            <EditIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton disabled={!model || model.common}
                                    onClick={this.handleRemoveModel}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomButton variant="outlined"
                                      disabled={!model || this.props.detectingPatterns}
                                      onClick={this.handleDeployModel}>
                            Deploy model
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomButton variant="outlined"
                                      disabled={!model || this.props.detectingPatterns}
                                      onClick={this.handelDetectPatterns}>
                            Detect patterns
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={!_.isNull(this.props.detectedPatternsNumber)}>
                            Patterns number: {this.props.detectedPatternsNumber}
                        </Collapse>
                    </Grid>
                </Grid>
            </div>
        );
    };

    renderValueForModelSelect = (value) => {
        if (_.isEmpty(value)) {
            return 'Select...';
        } else {
            return _.find(this.props.models, model => model.value === value).label;
        }
    };

    handleSelectModel = (e) => {
        if (this.state.selectedModel && this.state.selectedModel !== e.target.value) {
            this.props.onSelectModel();
        }
        this.setState({selectedModel: e.target.value});
    };

    handleRemoveModel = () => {
        this.props.onRemoveModel(this.state.selectedModel);
        this.setState({selectedModel: null});
    };

    handelDetectPatterns = () => {
        this.props.onDetectPatterns(this.state.selectedModel);
    };

    handleEditModel = () => {
        this.props.onEditModel();
    };

    handleDeployModel = () => {
        this.props.onDeployModel();
    }
}

MainPanel.propTypes = {
    charts: PropTypes.array,
    models: PropTypes.array,
    detectingPatterns: PropTypes.bool,
    onSelectChart: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onRemoveModel: PropTypes.func.isRequired,
    onDetectPatterns: PropTypes.func.isRequired,
    onSelectModel: PropTypes.func.isRequired,
    onEditModel: PropTypes.func.isRequired,
    onDeployModel: PropTypes.func.isRequired,
};

export default MainPanel;
