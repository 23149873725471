import {Auth} from 'aws-amplify';
import {StorageApi} from "../utils/storageApi";

export class Api {
    static fetchGetRequestWithToken(path) {
        let token = StorageApi.getToken();

        return fetch(path, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    static fetchPostRequestWithToken(path, data) {
        let token = StorageApi.getToken();

        return fetch(path, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    static fetchPutRequestWithToken(path, data) {
        let token = StorageApi.getToken();

        return fetch(path, {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    static fetchPatchRequestWithToken(path, data) {
        let token = StorageApi.getToken();

        return fetch(path, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    static fetchDeleteRequestWithToken(path) {
        let token = StorageApi.getToken();

        return fetch(path, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    }

    static getCharts() {
        let path = '/financial/v1/charts';

        return this.fetchGetRequestWithToken(path);
    }

    static authorize() {
        return Auth.federatedSignIn();
    }

    static logout() {
        return Auth.signOut();
    }

    static getChart(id, resampleSize) {
        let path = '/financial/v1/charts/' + id;

        if (resampleSize) {
            path += '?resample_size=' + resampleSize;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getModels() {
        let path = '/financial/v1/models';

        return this.fetchGetRequestWithToken(path);
    }

    static createModel(data) {
        let path = '/financial/v1/models';

        return this.fetchPostRequestWithToken(path, data);
    }

    static trainModel(id, data) {
        let path = '/financial/v1/models/' + id;

        return this.fetchPutRequestWithToken(path, data);
    }

    static editModel(id, data) {
        let path = '/financial/v1/models/' + id;

        return this.fetchPatchRequestWithToken(path, data)
    }

    static removeModel(id) {
        let path = '/financial/v1/models/' + id;

        return this.fetchDeleteRequestWithToken(path);
    }

    static detectPatterns(modelId, chartId, useConfidence, maxNumber) {
        let path = `/financial/v1/detection/models/${modelId}/charts/${chartId}?use_model_confidence_threshold=${useConfidence}`;
        if (maxNumber) {
            path += `&max_number=${maxNumber}`;
        }

        return this.fetchGetRequestWithToken(path);
    }

    static getModel(id) {
        let path = '/financial/v1/models/' + id;

        return this.fetchGetRequestWithToken(path);
    }

    static getChartCollections() {
        let path = '/financial/v1/chart-collections';

        return this.fetchGetRequestWithToken(path);
    }

    static getChartCollection(id) {
        let path = '/financial/v1/chart-collections/' + id;

        return this.fetchGetRequestWithToken(path);
    }

    static detectPatternsOnCollection(modelId, collectionId) {
        let path = '/financial/v1/detection/models/' + modelId + '/chart-collections/' + collectionId;

        return this.fetchGetRequestWithToken(path);
    }
}