import { connect } from 'react-redux';
import SidePanel from "./SidePanel";
import {getMode} from "../../reducers/mode/mode";

const mapStateToProps = state => {
    return {
        mode: getMode(state)
    }
};

export default connect(mapStateToProps)(SidePanel);