import {fetchGetCharts, loadingCharts} from "../../reducers/charts/charts";
import ChartComponent from "./ChartComponent";
import {connect} from "react-redux";
import {
    getBars,
    getChartData,
    loadingChart,
} from "../../reducers/charts/chart";
import {getMode} from "../../reducers/mode/mode";
import {addShape, hasPatterns, removeShape} from "../../reducers/patterns/patterns";
import {getDetectedPatterns, setPatternIds} from "../../reducers/patterns/patternsDetection";
import {getInterval, setVisibleInterval} from "../../reducers/charts/previewChart";

const mapStateToProps = state => {
    return {
        chart: getChartData(state),
        bars: getBars(state),
        fetching: loadingCharts(state) || loadingChart(state),
        mode: getMode(state),
        detectedPatterns: getDetectedPatterns(state),
        interval: getInterval(state),
        hasPatterns: hasPatterns(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCharts: () => {
            dispatch(fetchGetCharts());
        },
        setPatternIds: (ids) => dispatch(setPatternIds(ids)),
        setVisibleInterval: (interval) => dispatch(setVisibleInterval(interval)),
        addShape: (chartId, shape) => dispatch(addShape(chartId, shape)),
        removeShape: (chartId, shapeId) => dispatch(removeShape(chartId, shapeId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);