import { connect } from 'react-redux';
import ModelWithChart from "./ModelWithChart";
import {closeModal, DEPLOYMENT_MODAL, getActiveModal} from "../../../reducers/modal/modal";
import {getBars, getChartData} from "../../../reducers/charts/chart";
import {getSelectedChartForDeployment} from "../../../reducers/charts/chartCollection";
import {getPatternsForSelectedChart} from "../../../reducers/patterns/collectionPatterns";

const mapStateToProps = state => {
    return {
        isOpen: getActiveModal(state) === DEPLOYMENT_MODAL,
        chart: getChartData(state),
        bars: getBars(state),
        chartData: getSelectedChartForDeployment(state),
        patterns: getPatternsForSelectedChart(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(closeModal());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelWithChart);