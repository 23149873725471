import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import _ from 'lodash';
import {logout} from "../login/login";
import {fetchGetChart} from "./chart";

//- Actions
export const fetchGetChartCollectionFailed = createAction('FETCH_GET_CHART_COLLECTION_FAILED');
export const fetchGetChartCollectionSuccess = createAction('FETCH_GET_CHART_COLLECTION_SUCCESS');
export const fetchGetChartCollection = createAction(
    'FETCH_GET_CHART_COLLECTION',
    (id) => Api.getChartCollection(id),
    () => ({
        apiCall: true,
        onSuccess: fetchGetChartCollectionSuccess,
        onFail: fetchGetChartCollectionFailed,
        onUnauthorized: logout
    })
);

export const onGetChart = (stock, interval) => (dispatch, getState) => {
    let collectionData = getChartCollectionData(getState());
    let chartId = _.find(collectionData, chart => chart.stock_id === stock && chart.interval === interval).id;
    dispatch(setSelectedChart({id: chartId, stock, interval}));
    dispatch(fetchGetChart(chartId));
};

export const setSelectedChart = createAction('SELECTED_CHART_SET', chartData => chartData);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false,
    selectedChart: null
};

//- Reducers
export default handleActions({

    FETCH_GET_CHART_COLLECTION: (state) => {
        return {...state, data: null, error: null, fetching: true};
    },
    FETCH_GET_CHART_COLLECTION_FAILED: (state, action) => {
        return {...state, data: null, error: action.payload, fetching: false};
    },
    FETCH_GET_CHART_COLLECTION_SUCCESS: (state, action) => {
        return {...state, data: action.payload.charts, error: null, fetching: false};
    },
    SELECTED_CHART_SET: (state, action) => {
        return {...state, selectedChart: action.payload};
    }

}, initialState);

//- Selectors
export const getChartCollection = state => state.chartCollection;

export const getChartCollectionData = state => getChartCollection(state).data;

export const getListOfStocks = state => {
    let data = getChartCollectionData(state);

    if (_.isNil(data)) {
        return null;
    }

    return _.chain(data)
        .map(chart => chart.stock_id)
        .uniq()
        .value();
};

export const getListOfIntervals = state => {
    let data = getChartCollectionData(state);

    if (_.isNil(data)) {
        return null;
    }

    return _.chain(data)
        .map(chart => chart.interval)
        .uniq()
        .value();
};

export const loadingChartCollection = state => getChartCollection(state).fetching;

export const getSelectedChartForDeployment = state => getChartCollection(state).selectedChart;
