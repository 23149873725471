import { connect } from 'react-redux';
import HeatmapComponent from "./HeatmapComponent";
import {
    detectingPatternsForCollection,
    getCollectionPatternsCountData
} from "../../reducers/patterns/collectionPatterns";
import {
    getListOfIntervals,
    getListOfStocks,
    loadingChartCollection,
    onGetChart
} from "../../reducers/charts/chartCollection";
import {getMode, setMainMode} from "../../reducers/mode/mode";
import {DEPLOYMENT_MODAL, openModal} from "../../reducers/modal/modal";

const mapStateToProps = state => {
    getCollectionPatternsCountData(state);
    return {
        fetching: detectingPatternsForCollection(state) || loadingChartCollection(state),
        heatmap: getCollectionPatternsCountData(state),
        stocks: getListOfStocks(state),
        intervals: getListOfIntervals(state),
        mode: getMode(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRedirect: () => dispatch(setMainMode()),
        onGetChart: (stock, interval) => {
            dispatch(onGetChart(stock, interval));
            dispatch(openModal(DEPLOYMENT_MODAL));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeatmapComponent);