import _ from "lodash";

export class ChartApi {
    static onReady = (cb) => {
        let config = {
            supported_resolutions: ['60']
        };
        setTimeout(() => cb(config), 0);
    };

    static searchSymbols = (userInput, exchange, symbolType, onResultReadyCallback) => {

    };

    static resolveSymbol = (getResolution) => (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        let symbol_stub = {
            name: symbolName,
            type: 'stock',
            session: '24x7',
            timezone: 'Etc/UTC',
            ticker: symbolName,
            minmov: 1,
            pricescale: 100,
            has_intraday: true,
            intraday_multipliers: ['60'],
            supported_resolutions: [getResolution()],
            data_status: 'streaming',
        };
        setTimeout(() => {
            onSymbolResolvedCallback(symbol_stub);
        }, 0);
    };

    static getBars = (getBars) => (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) => {
        let bars = getBars();
        if (firstDataRequest) {
            setTimeout(() => {
                onHistoryCallback(bars || [], {noData: _.isNil(bars) || bars.length === 0})
            }, 0);
        } else {
            setTimeout(() => {
                onHistoryCallback([], {noData: true})
            }, 0);
        }
    };

    static subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {

    };

    static unsubscribeBars = subscriberUID => {

    };
}