import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import _ from 'lodash';
import {clearPatterns, getPatternsData} from "../patterns/patterns";
import {setMainMode} from "../mode/mode";
import {logout} from "../login/login";
import {openModal, PATTERNS_NUMBER_WARNING_MODAL} from "../modal/modal";
import {Utils} from "../../utils/utils";
import {clearDetectedPatterns, setSliderValue} from "../patterns/patternsDetection";

const DEFAULT_MODEL_NAME = '_saffronblue_default_model';
const MIN_PATTERNS_NUMBER = 3;

//- Actions
export const fetchGetModelsFailed = createAction('FETCH_GET_MODELS_FAILED');
export const fetchGetModelsSuccess = createAction('FETCH_GET_MODELS_SUCCESS');

export const onGetModelsSuccess = (data) => (dispatch) => {
    let models = data.models;
    let temporaryModel = _.find(models, model => model.name === DEFAULT_MODEL_NAME);

    if (!_.isNil(temporaryModel)) {
        dispatch(setTemporaryModel(temporaryModel.id));
        dispatch(fetchEditModelAndFinishTraining(temporaryModel.id, {confidence_threshold: null}));
    }

    models = models.filter(model => model.name !== DEFAULT_MODEL_NAME);
    dispatch(fetchGetModelsSuccess(models));
};

export const fetchGetModels = createAction(
    'FETCH_GET_MODELS',
    () => Api.getModels(),
    () => ({
        apiCall: true,
        onSuccess: onGetModelsSuccess,
        onFail: fetchGetModelsFailed,
        onUnauthorized: logout
    })
);

export const fetchCreateModelFailed = createAction('FETCH_CREATE_MODEL_FAILED');
export const fetchCreateModelSuccess = createAction('FETCH_CREATE_MODEL_SUCCESS');
export const onCreateModelSuccess = (data) => (dispatch) => {
    dispatch(fetchGetModel(data.id));
    dispatch(fetchCreateModelSuccess(data.id));
};
export const fetchCreateModel = createAction(
    'FETCH_CREATE_MODEL',
    (data) => Api.createModel(data),
    () => ({
        apiCall: true,
        onSuccess: onTrainingFinish(onCreateModelSuccess),
        onFail: onTrainingFinish(fetchCreateModelFailed),
        onUnauthorized: logout
    })
);


export const fetchTrainModelFailed = createAction('FETCH_TRAIN_MODEL_FAILED');
export const onTrainModelSuccess = () => (dispatch, getState) => {
    let model = getTemporaryModel(getState());
    dispatch(fetchGetModel(model));
};

export const fetchTrainModel = createAction(
    'FETCH_TRAIN_MODEL',
    (id, data) => Api.trainModel(id, data),
    () => ({
        apiCall: true,
        onSuccess: onTrainingFinish(onTrainModelSuccess),
        onFail: onTrainingFinish(fetchTrainModelFailed),
        onUnauthorized: logout
    })
);

export const onTrainingFinish = (callback) => (data) => (dispatch) => {
    dispatch(finishTraining());
    dispatch(setTemporaryModelTrained(true));
    dispatch(callback(data));
};

export const trainModel = () => (dispatch, getState) => {
    let modelId = getTemporaryModel(getState());
    let data = getTrainingPatterns(getState());

    let number = 0;
    _.map(data, patterns => {
        number += patterns.patterns.length
    });
    if (number < MIN_PATTERNS_NUMBER) {
        dispatch(openModal(PATTERNS_NUMBER_WARNING_MODAL));
        return;
    }

    dispatch(setTrainingPatternsNumber(number));

    let model = {data};
    if (!temporaryModelCreated(getState())) {
        model.name = DEFAULT_MODEL_NAME;
        dispatch(fetchCreateModel(model));
    } else {
        dispatch(fetchTrainModel(modelId, model));
    }
    dispatch(startTraining());

};

export const startTraining = createAction('TRAINING_START');
export const finishTraining = createAction('TRAINING_FINISH');

export const fetchSaveModelFailed = createAction('FETCH_SAVE_MODEL_FAILED');
export const fetchSaveModelSuccess = () => (dispatch) => {
    dispatch(discardChanges());
    dispatch(setMainMode());
};

export const fetchSaveModel = createAction(
    'FETCH_CREATE_MODEL',
    (data) => Api.createModel(data),
    () => ({
        apiCall: true,
        onSuccess: onTrainingFinish(fetchSaveModelSuccess),
        onFail: onTrainingFinish(fetchSaveModelFailed),
        onUnauthorized: logout
    })
);

export const fetchEditAndDiscardModelSuccess = () => (dispatch) => {
    dispatch(discardChanges());
    dispatch(setMainMode());
};
export const fetchEditModelFailed = createAction('FETCH_EDIT_MODEL_FAILED');
export const fetchEditModelSuccess = createAction('FETCH_EDIT_MODEL_SUCCESS');

export const fetchEditAndDiscardModel = createAction(
    'FETCH_EDIT_MODEL',
    (id, data) => Api.editModel(id, data),
    () => ({
        apiCall: true,
        onSuccess: onTrainingFinish(fetchEditAndDiscardModelSuccess),
        onFail: onTrainingFinish(fetchEditModelFailed),
        onUnauthorized: logout
    })
);

export const fetchEditModelAndFinishTraining = createAction(
    'FETCH_EDIT_MODEL',
    (id, data) => Api.editModel(id, data),
    () => ({
        apiCall: true,
        onSuccess: onTrainingFinish(fetchEditModelSuccess),
        onFail: onTrainingFinish(fetchEditModelFailed),
        onUnauthorized: logout
    })
);

export const onFetchEditModelSuccess = () => (dispatch) => {
    dispatch(fetchGetModels());
};

export const fetchEditModel = createAction(
    'FETCH_EDIT_MODEL',
    (id, data) => Api.editModel(id, data),
    () => ({
        apiCall: true,
        onSuccess: onFetchEditModelSuccess,
        onFail: fetchEditModelFailed,
        onUnauthorized: logout
    })
);

export const onSaveModel = (name) => (dispatch, getState) => {
    if (temporaryModelCreated(getState())) {
        let modelId = getTemporaryModel(getState());
        let data = {name};

        let confidence = getConfidence(getState());
        if (confidence) {
            data.confidence_threshold = confidence;
        }
        dispatch(startTraining());
        dispatch(fetchEditAndDiscardModel(modelId, data));
    } else {
        let patterns = getTrainingPatterns(getState());
        let data = {
            name: name,
            data: patterns
        };
        let confidence = getConfidence(getState());
        if (confidence) {
            data.confidence_threshold = confidence;
        }
        dispatch(startTraining());
        dispatch(fetchSaveModel(data));
    }
};

export const discardChanges = () => (dispatch) => {
    dispatch(clearTemporaryModel());
    dispatch(clearPatterns());
    dispatch(setSliderValue(null));
    dispatch(setConfidence(null));
    dispatch(clearDetectedPatterns());
};

export const resetModel = () => (dispatch, getState) => {
    if (temporaryModelCreated(getState())) {
        dispatch(fetchRemoveModel(getTemporaryModel(getState())));
    }
    dispatch(discardChanges());
};

export const clearTemporaryModel = createAction('TEMPORARY_MODEL_CLEAR');
export const setTemporaryModel = createAction('TEMPORARY_MODEL_SET');

export const setConfidence = createAction('CONFIDENCE_SET');

export const fetchRemoveModelSuccess = () => (dispatch) => {
    dispatch(fetchGetModels());
};
export const fetchRemoveModelFailed = createAction('FETCH_REMOVE_MODEL_FAILED');

export const fetchRemoveModel = createAction(
    'FETCH_REMOVE_MODEL',
    (id) => Api.removeModel(id),
    () => ({
        apiCall: true,
        onSuccess: fetchRemoveModelSuccess,
        onFail: fetchRemoveModelFailed,
        onUnauthorized: logout
    })
);

export const removeModel = () => (dispatch, getState) => {
    if (temporaryModelCreated(getState())) {
        dispatch(fetchRemoveModel(getTemporaryModel(getState())));
    }
    dispatch(discardChanges());
    dispatch(setMainMode());
};

export const setTemporaryModelTrained = createAction('TEMPORARY_MODEL_TRAINED_SET', trained => trained);

export const fetchGetModelSuccess = createAction('FETCH_GET_MODEL_SUCCESS');
export const fetchGetModelFailed = createAction('FETCH_GET_MODEL_FAILED');

export const fetchGetModel = createAction(
    'FETCH_GET_MODEL',
    (id) => Api.getModel(id),
    () => ({
        apiCall: true,
        onSuccess: fetchGetModelSuccess,
        onFail: fetchGetModelFailed,
        onUnauthorized: logout
    })
);

export const setTrainingPatternsNumber = createAction('TRAINING_PATTERNS_NUMBER_SET', number => number);

//- State
const initialState = {
    temporaryModel: null,
    temporaryModelTrained: false,
    confidence: null,
    data: null,
    error: null,
    fetching: false,
    training: false,
    trainingTime: null,
    trainingPatternsNumber: null
};

//- Reducers
export default handleActions({

    FETCH_GET_MODELS: (state) => {
        return {...state, data: null, error: null, fetching: true};
    },
    FETCH_GET_MODELS_FAILED: (state, action) => {
        return {...state, data: null, error: action.payload, fetching: false};
    },
    FETCH_GET_MODELS_SUCCESS: (state, action) => {
        return {...state, data: action.payload, error: null, fetching: false};
    },
    FETCH_CREATE_MODEL_SUCCESS: (state, action) => {
        return {...state, temporaryModel: action.payload};
    },
    TRAINING_START: (state) => {
        return {...state, training: true, trainingTime: Date.now()};
    },
    TRAINING_FINISH: (state) => {
        return {...state, training: false, trainingTime: Date.now() - state.trainingTime};
    },
    TEMPORARY_MODEL_CLEAR: (state) => {
        return {...state, temporaryModel: null, temporaryModelTrained: false};
    },
    CONFIDENCE_SET: (state, action) => {
        return {...state, confidence: action.payload};
    },
    TEMPORARY_MODEL_SET: (state, action) => {
        return {...state, temporaryModel: action.payload};
    },
    TEMPORARY_MODEL_TRAINED_SET: (state, action) => {
        return {...state, temporaryModelTrained: action.payload};
    },
    FETCH_GET_MODEL_SUCCESS: (state, action) => {
        return {...state, confidence: action.payload.confidence_threshold};
    },
    FETCH_GET_MODEL: (state) => {
        return {...state, confidence: null};
    },
    TRAINING_PATTERNS_NUMBER_SET: (state, action) => {
        return {...state, trainingPatternsNumber: action.payload};
    }

}, initialState);

//- Selectors
export const getModels = state => state.models;

export const getModelsData = state => {
    let data = getModels(state).data;

    if (!data || data.length === 0) {
        return null;
    }

    return _.map(data, model => ({
        value: model.id,
        label: model.name,
        common: model.common
    }));
};

export const getTemporaryModel = state => getModels(state).temporaryModel;

export const temporaryModelCreated = state => !_.isNil(getTemporaryModel(state));

export const temporaryModelTrained = state => getModels(state).temporaryModelTrained;

export const trainingModel = state => getModels(state).training;

export const getConfidence = state => getModels(state).confidence;

export const getTrainingTime = state => getModels(state).trainingTime;

export const getTrainingPatterns = state => {
    let data = getPatternsData(state);

    if (_.isNil(data)) {
        return null;
    }

    return _.map(data, (patterns, chartId) => ({
        chart_id: chartId,
        patterns: _.map(patterns, pattern => ({
            ...pattern,
            shapes: _.map(pattern.shapes, shape => ({
                shape_type: shape.shape_type,
                points: _.map(shape.points, point => ({
                    datetime: Utils.convertTimestampToDatetime(point.time * 1000),
                    price: point.price
                }))
            }))
        }))
    }));
};

export const getTrainingPatternsNumber = state => getModels(state).trainingPatternsNumber;