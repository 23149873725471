import {connect} from 'react-redux';
import MainPanel from "./MainPanel";
import {getChartsDataOptions} from "../../../reducers/charts/charts";
import {fetchGetChart} from "../../../reducers/charts/chart";
import {fetchGetModels, fetchRemoveModel, getModelsData} from "../../../reducers/models/models";
import {
    clearDetectedPatterns,
    detectingPatterns,
    detectPatterns, getDetectedPatternsNumber,
} from "../../../reducers/patterns/patternsDetection";
import {fetchGetPreviewChart} from "../../../reducers/charts/previewChart";
import {CHANGE_MODEL_MODAL, DEPLOY_MODEL_MODAL, openModal} from "../../../reducers/modal/modal";
import {fetchGetChartCollections} from "../../../reducers/charts/chartCollections";

const mapStateToProps = state => {
    return {
        charts: getChartsDataOptions(state),
        models: getModelsData(state),
        detectingPatterns: detectingPatterns(state),
        detectedPatternsNumber: getDetectedPatternsNumber(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onMount: () => dispatch(fetchGetModels()),
        onSelectChart: (id) => {
            dispatch(fetchGetChart(id));
            dispatch(fetchGetPreviewChart(id));
            dispatch(clearDetectedPatterns());
        },
        onRemoveModel: (id) => dispatch(fetchRemoveModel(id)),
        onDetectPatterns: (modeId) => dispatch(detectPatterns(modeId)),
        onSelectModel: () => dispatch(clearDetectedPatterns()),
        onEditModel: () => dispatch(openModal(CHANGE_MODEL_MODAL)),
        onDeployModel: () => {
            dispatch(fetchGetChartCollections());
            dispatch(openModal(DEPLOY_MODEL_MODAL));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPanel);