import { from, of } from 'rxjs';
import { filter, mergeMap, catchError } from 'rxjs/operators';
import _ from 'lodash';

const EMPTY_CONTENT = "0";
const UNAUTHORIZED_CODE = 401;
const NO_CONTENT_CODE = 204;
export const UNAUTHORIZED = "401";

export const handleApiCallEpic = (action$) => {
    return action$.pipe(
        filter(action => !_.isNil(action.meta) && action.meta.apiCall),
        mergeMap(action => {
            return from(action.payload).pipe(
                mergeMap(response => {
                    if (!response.ok) {
                        switch (response.status) {
                            case UNAUTHORIZED_CODE:
                                throw new Error(UNAUTHORIZED);
                            default:
                                throw new Error(response.status);
                        }
                    }
                    let responseType = response.headers.get('Content-Type');
                    if (!_.isNil(responseType) && responseType.startsWith('application/json') && response.status !== NO_CONTENT_CODE) {
                        return from(response.json());
                    } else {
                        return from(EMPTY_CONTENT);
                    }
                }),
                mergeMap(result => {
                    return of(action.meta.onSuccess(result));
                }),
                catchError(error => {
                    if (error.message === UNAUTHORIZED && action.meta.onUnauthorized) {
                        return of(action.meta.onFail(error.message), action.meta.onUnauthorized());
                    }
                    return of(
                        action.meta.onFail(error.message)
                    )
                })
            )
        })
    );
};