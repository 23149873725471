import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogActions} from "@material-ui/core";
import CustomButton from "../button/CustomButton";

class WarningModal extends Component {
    render() {
        return (
            <Dialog open={this.props.isOpen} onClose={this.props.onClose}>
                <DialogTitle>
                    {this.props.label}
                </DialogTitle>
                <DialogActions>
                    <CustomButton
                        variant="outlined"
                        onClick={this.props.onClose}>
                        Ok
                    </CustomButton>
                </DialogActions>
            </Dialog>
        );
    }
}

WarningModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default WarningModal;
