import {connect} from 'react-redux';
import TrainingPanel from "./TrainingPanel";
import {getChartsDataOptions} from "../../../reducers/charts/charts";
import {fetchGetChart, loadingChart} from "../../../reducers/charts/chart";
import {
    getConfidence, getTrainingPatternsNumber,
    getTrainingTime,
    temporaryModelTrained,
    trainingModel,
    trainModel
} from "../../../reducers/models/models";
import {
    clearDetectedPatterns,
    detectingPatterns,
    detectPatternsForCurrentModel,
    getMaxSliderValue,
    getSliderValue,
    hasDetectedPatterns,
    onSetSliderValue,
    setSliderValue
} from "../../../reducers/patterns/patternsDetection";
import {Utils} from "../../../utils/utils";
import {fetchGetPreviewChart} from "../../../reducers/charts/previewChart";
import {getPatternsNumber} from "../../../reducers/patterns/patterns";

const mapStateToProps = state => {
    return {
        charts: getChartsDataOptions(state),
        modelCreated: temporaryModelTrained(state),
        training: trainingModel(state),
        detectingPatterns: detectingPatterns(state),
        loadingChart: loadingChart(state),
        maxSliderValue: getMaxSliderValue(state),
        sliderValue: getSliderValue(state),
        hasPatterns: hasDetectedPatterns(state),
        confidence: getConfidence(state),
        trainingTime: Utils.formatTimeInterval(getTrainingTime(state)),
        trainingPatternsNumber: getTrainingPatternsNumber(state),
        selectedPatternsNumber: getPatternsNumber(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectChart: (id) => {
            dispatch(fetchGetChart(id));
            dispatch(fetchGetPreviewChart(id));
            dispatch(clearDetectedPatterns());
        },
        onTrainModel: () => dispatch(trainModel()),
        onSetSliderValue: (confidence) => dispatch(setSliderValue(confidence)),
        onSetConfidence: (sliderValue) => dispatch(onSetSliderValue(sliderValue)),
        onDetectPatterns: () => dispatch(detectPatternsForCurrentModel())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingPanel);