import { connect } from 'react-redux';
import ChangeModelModal from "./ChangeModelModal";
import {fetchEditModel} from "../../../../reducers/models/models";
import {CHANGE_MODEL_MODAL, closeModal, getActiveModal} from "../../../../reducers/modal/modal";

const mapStateToProps = state => {
    return {
        isOpen: getActiveModal(state) === CHANGE_MODEL_MODAL
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSave: (id, data) => {
            dispatch(fetchEditModel(id, data));
        },
        onClose: () => {
            dispatch(closeModal());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeModelModal);