import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const styles = {
    root: {
        width: '100%'
    },
    label: {
        textTransform: 'none',
    },
};


export default withStyles(styles)(Button);
