import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ChartApi} from "../../api/chartApi";
import './ChartComponent.css';
import _ from 'lodash';
import {Utils} from "../../utils/utils";

const DISABLED_FEATURES = ['header_widget', 'legend_widget', 'timeframes_toolbar'];
const DETECTED_PATTERN_OVERRIDES = {
    backgroundColor: '#96bcfa',
    color: '#96bcfa'
};

class ChartComponent extends Component {
    constructor(props) {
        super(props);

        this.widget = null;

        this.props.onGetCharts();

        this.state = {
            detectedPatterns: [],
            ignoreDrawing: false,
            interval: null,
            ignoreChangeRange: false,
            chartReady: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.chart, prevProps.chart) && this.props.chart) {
            if (!this.widget) {
                this.setState({chartReady: false});
                this.setupWidget();
            } else {
                this.widget.setSymbol(this.props.chart.name, this.getResolution(), () => {
                });
            }
        }

        if ((!_.isEqual(prevProps.detectedPatterns, this.props.detectedPatterns) ||
            (_.isEmpty(this.props.detectedPatterns) && !_.isEmpty(this.state.detectedPatterns))) && this.state.chartReady) {
            if (!_.isNil(this.props.detectedPatterns)) {
                this.drawFirstRangeDetectedPatterns();
            } else {
                this.removeDetectedPatterns();
            }
        }

        if (!_.isEqual(this.props.interval, prevProps.interval) && !_.isNil(this.props.interval) && !_.isNil(prevProps.interval) &&
            !_.isEqual(this.props.interval, this.state.interval)) {
            this.setState({ignoreChangeRange: true}, () => {
                this.widget.chart().setVisibleRange(this.props.interval).then(() => {
                    this.setState({ignoreChangeRange: false});
                });
            });
        }

        let shapes = this.state.chartReady ? this.widget.chart().getAllShapes() : [];
        shapes = shapes.filter(shape => !_.find(this.state.detectedPatterns, pattern => pattern.id === shape.id));
        if ((prevProps.hasPatterns && !this.props.hasPatterns) || (!this.props.hasPatterns && shapes.length !== 0)) {
            _.map(shapes, shape => {
                this.widget.chart().removeEntity(shape.id);
            });
        }
    }

    removeDetectedPatterns = () => {
        let chart = this.widget.chart();
        _.map(this.state.detectedPatterns, pattern => {
            if (chart.getShapeById(pattern.id)) {
                chart.removeEntity(pattern.id);
            }
        });
        this.props.setPatternIds([]);
        this.setState({detectedPatterns: []});
    };

    drawFirstRangeDetectedPatterns = () => {
        //TODO: add groups handling
        this.setState({ignoreDrawing: true}, () => {
            let chart = this.widget.chart();
            let visibleRange = chart.getVisibleRange();

            _.map(this.state.detectedPatterns, pattern => {
                chart.removeEntity(pattern.id);
            });

            let detectedPatterns = [];
            _.chain(this.props.detectedPatterns)
                .filter(shapes => _.every(shapes, shape =>
                    _.every(shape.points, point =>
                        point.time >= visibleRange.from && point.time <= visibleRange.to)))
                .map(shapes => _.map(shapes, shape => {
                    let id = chart.createMultipointShape(shape.points, {
                        shape: shape.shape_type,
                        overrides: DETECTED_PATTERN_OVERRIDES,
                        lock: true
                    });
                    detectedPatterns.push({
                        id,
                        points: shape.points
                    });
                }))
                .value();

            this.props.setPatternIds(detectedPatterns);
            this.setState({detectedPatterns, ignoreDrawing: false});
        });
    };

    drawPatterns = (visibleRange) => {
        //TODO: add groups handling
        if (!this.props.detectedPatterns) {
            return;
        }
        this.setState({ignoreDrawing: true}, () => {
            let chart = this.widget.chart();
            let detectedPatterns = [...this.state.detectedPatterns];

            _.chain(this.props.detectedPatterns)
                .filter(shapes => _.every(shapes, shape =>
                    _.every(shape.points, point =>
                        point.time >= visibleRange.from && point.time <= visibleRange.to) &&
                    !_.find(detectedPatterns, pattern => _.isEqual(pattern.points, shape.points))))
                .map(shapes => _.map(shapes, shape => {
                    let id = chart.createMultipointShape(shape.points, {
                        shape: shape.shape_type,
                        overrides: DETECTED_PATTERN_OVERRIDES,
                        lock: true
                    });
                    detectedPatterns.push({
                        id,
                        points: shape.points
                    });
                }))
                .value();

            this.props.setPatternIds(detectedPatterns);
            this.setState({detectedPatterns, ignoreDrawing: false});
        });
    };

    getBars = () => {
        return this.props.bars;
    };

    getResolution = () => {
        let interval = this.props.bars[1].time - this.props.bars[0].time;
        return Utils.getResolutionForInterval(interval);
    };

    setupWidget = () => {
        const widgetOptions = {
            symbol: this.props.chart.name,
            datafeed: {
                onReady: ChartApi.onReady,
                searchSymbols: ChartApi.searchSymbols,
                resolveSymbol: ChartApi.resolveSymbol(this.getResolution),
                getBars: ChartApi.getBars(this.getBars),
                subscribeBars: ChartApi.subscribeBars,
                unsubscribeBars: ChartApi.unsubscribeBars,
            },
            interval: this.props.chart.interval,
            container_id: 'chart_container',
            library_path: '/charting_library/',
            autosize: true,
            disabled_features: DISABLED_FEATURES
        };
        const widget = new window.TradingView.widget(widgetOptions);
        this.widget = widget;
        widget.onChartReady(() => {
            this.setState({chartReady: true});
            widget.chart().executeActionById("stayInDrawingModeAction");

            this.setVisibleInterval(widget.chart().getVisibleRange());

            widget.subscribe('mouse_up', () => {
                if (!widget.chart().getCheckableActionState("stayInDrawingModeAction")) {
                    widget.chart().executeActionById("stayInDrawingModeAction");
                }
                this.handleSelection();
            });

            widget.subscribe('drawing_event', (id, event) => {
                if (_.find(this.state.detectedPatterns, pattern => pattern.id === id)) {
                    return;
                }
                switch (event) {
                    case 'remove':
                        this.props.removeShape(this.props.chart.id, id);
                        break;
                    case 'move':
                        let chart = widget.chart();
                        let shape = chart.getShapeById(id);
                        let shapes = chart.getAllShapes();
                        let data = {
                            id: id,
                            shape_type: shapes.find(shape => shape.id === id).name,
                            points: shape.getPoints()
                        };
                        this.props.addShape(this.props.chart.id, data);
                        break;
                    default:
                        break;
                }
            });

            widget.chart().selection().onChanged().subscribe(null, () => {
                this.handleSelection();
            });

            widget.chart().onVisibleRangeChanged().subscribe(null, (visibleRange) => {
                this.drawPatterns(visibleRange);
                if (!this.state.ignoreChangeRange) {
                    this.setVisibleInterval(visibleRange);
                }
            })
        });
    };

    setVisibleInterval = (interval) => {
        this.setState({interval}, () => this.props.setVisibleInterval(interval));
    };

    handleSelection = () => {
        let chart = this.widget.chart();
        let selection = chart.selection().allSources();
        if (selection.length === 1) {
            let entityId = selection[0];
            if (_.find(this.state.detectedPatterns, pattern => pattern.id === entityId)) {
                return;
            }

            let shape;
            try {
                shape = chart.getShapeById(entityId);
            } catch (e) {
                shape = null;
            }

            if (shape) {
                let shapes = chart.getAllShapes();
                let data = {
                    id: entityId,
                    shape_type: shapes.find(shape => shape.id === entityId).name,
                    points: shape.getPoints()
                };
                this.props.addShape(this.props.chart.id, data);
            }
        }
    };

    render() {
        return (
            <div className='chart-component'>
                <div id='chart_container'/>
            </div>
        );
    }

    componentWillUnmount() {
        if (this.widget !== null) {
            this.widget.remove();
            this.widget = null;
        }
    }
}

ChartComponent.propTypes = {
    chart: PropTypes.object,
    bars: PropTypes.array,
    fetching: PropTypes.bool,
    mode: PropTypes.string,
    detectedPatterns: PropTypes.array,
    interval: PropTypes.object,
    hasPatterns: PropTypes.bool,
    onGetCharts: PropTypes.func.isRequired,
    setPatternIds: PropTypes.func.isRequired,
    setVisibleInterval: PropTypes.func.isRequired,
    addShape: PropTypes.func.isRequired,
    removeShape: PropTypes.func.isRequired,
};

export default ChartComponent;