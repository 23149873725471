import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogActions, TextField} from "@material-ui/core";
import CustomButton from "../../../common/button/CustomButton";
import './ChangeModelModal.css';
import _ from 'lodash';

class ChangeModelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            error: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen && this.props.data) {
            this.setState({name: this.props.data.label, error: false});
        }
    }

    render() {
        return (
            <Dialog classes={{paper: 'edit-model-modal '}} open={this.props.isOpen} onClose={this.props.onClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit model</DialogTitle>
                <DialogContent classes={{root: 'edit-model-modal-content'}}>
                    <TextField
                        classes={{root: 'custom-input'}}
                        error={this.state.error}
                        helperText={this.state.error? "Please, fill out this field" : ''}
                        label="Model name"
                        value={this.state.name}
                        onChange={this.handleChange} />
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        variant="outlined"
                        onClick={this.props.onClose}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                        variant="outlined"
                        onClick={this.handleSave}>
                        Save
                    </CustomButton>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange = (e) => {
        this.setState({name: e.target.value, error: false});
    };

    handleSave = () => {
        if (_.isEmpty(this.state.name)) {
            this.setState({error: true});
        } else {
            this.props.onSave(this.props.data.value, {name: this.state.name});
            this.props.onClose();
        }
    };
}

ChangeModelModal.propTypes = {
    data: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ChangeModelModal;
