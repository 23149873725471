import { combineReducers } from 'redux';
import {handleApiCallEpic} from "./epics/handleApiCall";
import {combineEpics} from "redux-observable";

import charts from './charts/charts';
import chart from './charts/chart';
import login from './login/login';
import mode from './mode/mode';
import models from './models/models';
import patterns from './patterns/patterns';
import patternsDetection from './patterns/patternsDetection';
import modal from './modal/modal';
import previewChart from './charts/previewChart';
import chartCollections from './charts/chartCollections';
import chartCollection from './charts/chartCollection';
import collectionPatterns from './patterns/collectionPatterns';

export const rootReducer = combineReducers({
    charts,
    login,
    chart,
    mode,
    models,
    patterns,
    patternsDetection,
    modal,
    previewChart,
    chartCollections,
    chartCollection,
    collectionPatterns
});

export const rootEpic = combineEpics(
    handleApiCallEpic
);