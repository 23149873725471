import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent} from "@material-ui/core";
import './ModelWithChart.css';
import _ from 'lodash';
import {Utils} from "../../../utils/utils";
import {ChartApi} from "../../../api/chartApi";

const DISABLED_FEATURES = ['header_widget', 'legend_widget', 'timeframes_toolbar', 'chart_scroll', 'chart_zoom', 'left_toolbar', 'control_bar'];
const TIME_FRAME = '100000M';

class ModelWithChart extends Component {
    constructor(props) {
        super(props);

        this.widget = null;

        this.state = {
            patterns: [],
            chartReady: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.chart, prevProps.chart) && this.props.chart) {
            if (this.widget) {
                this.widget.remove();
            }
            this.setupWidget();
        }

        if ((!_.isEqual(prevProps.patterns, this.props.patterns) || _.isEmpty(this.state.patterns)) && !_.isEmpty(this.props.patterns) && this.state.chartReady) {
            this.drawPatterns();
        }
    }

    drawPatterns = () => {
        let chart = this.widget.chart();
        let patterns = [];
        _.forEach(this.props.patterns, shapes => {
            _.map(shapes, shape => {
                let id = chart.createMultipointShape(shape.points, {
                    shape: shape.shape_type,
                    lock: true,
                    zOrder: 'top',
                    disableSelection: true,
                    overrides: {
                        'backgroundColor': '#3c65a7',
                        'color': '#3c65a7'
                    },
                });
                patterns.push(id);
            })
        });

        this.setState({patterns});
    };

    getBars = () => {
        return this.props.bars;
    };

    getResolution = () => {
        let interval = this.props.bars[1].time - this.props.bars[0].time;
        return Utils.getResolutionForInterval(interval);
    };

    setupWidget = () => {
        const widgetOptions = {
            symbol: this.props.chart.name,
            datafeed: {
                onReady: ChartApi.onReady,
                searchSymbols: ChartApi.searchSymbols,
                resolveSymbol: ChartApi.resolveSymbol(this.getResolution),
                getBars: ChartApi.getBars(this.getBars),
                subscribeBars: ChartApi.subscribeBars,
                unsubscribeBars: ChartApi.unsubscribeBars,
            },
            interval: this.props.chart.interval,
            container_id: 'deploy_chart',
            library_path: '/charting_library/',
            autosize: true,
            disabled_features: DISABLED_FEATURES,
            timeframe: TIME_FRAME
        };
        const widget = new window.TradingView.widget(widgetOptions);
        this.widget = widget;
        widget.onChartReady(() => {
            this.setState({chartReady: true});
        });
    };

    getLabel = () => {
        if (!this.props.chartData) {
            return '';
        }

        return `Stock: ${this.props.chartData.stock}, interval: ${Utils.formatHeatmapInterval(this.props.chartData.interval)}`;
    };

    render() {
        return (
            <Dialog classes={{paper: 'deployment-modal '}} open={this.props.isOpen} onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.getLabel()}</DialogTitle>
                <DialogContent classes={{root: 'deployment-modal-content'}}>
                    <div id='deploy_chart'/>
                </DialogContent>
            </Dialog>
        );
    }

    handleClose = () => {
        this.widget.remove();
        this.widget = null;
        this.props.onClose();
        this.setState({chartReady: false, patterns: []});
    }
}

ModelWithChart.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    chart: PropTypes.object,
    bars: PropTypes.array,
    chartData: PropTypes.object,
    patterns: PropTypes.array,
    onClose: PropTypes.func.isRequired,
};

export default ModelWithChart;
