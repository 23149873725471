import {connect} from 'react-redux';
import Toolbar from "./Toolbar";
import {logout} from "../../reducers/login/login";
import {getMode, setMainMode, setTrainingMode} from "../../reducers/mode/mode";
import {loadingChart} from "../../reducers/charts/chart";
import {removeModel, trainingModel, temporaryModelTrained, resetModel} from "../../reducers/models/models";
import {clearDetectedPatterns, setSliderValue} from "../../reducers/patterns/patternsDetection";
import {clearPatterns, hasPatterns} from "../../reducers/patterns/patterns";
import {openModal, SAVE_MODEL_MODAL} from "../../reducers/modal/modal";
import {detectingPatternsForCollection} from "../../reducers/patterns/collectionPatterns";
import {loadingChartCollection} from "../../reducers/charts/chartCollection";

const mapStateToProps = state => {
    return {
        mode: getMode(state),
        fetching: loadingChart(state) ||
            trainingModel(state) ||
            detectingPatternsForCollection(state) ||
            loadingChartCollection(state),
        modelCreated: temporaryModelTrained(state),
        hasPatterns: hasPatterns(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logout()),
        onSetMainMode: () => {
            dispatch(setMainMode());
            dispatch(clearDetectedPatterns());
            dispatch(setSliderValue(null));
            dispatch(clearPatterns());
        },
        onSetTrainingMode: () => {
            dispatch(setTrainingMode());
            dispatch(clearDetectedPatterns());
            dispatch(setSliderValue(null));
        },
        onRemoveModel: () => {
            dispatch(removeModel());
        },
        onResetModel: () => {
            dispatch(resetModel());
        },
        onSave: () => dispatch(openModal(SAVE_MODEL_MODAL)),
        onFinishDeployment: () => {
            dispatch(setMainMode());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);