import {createAction, handleActions} from 'redux-actions';
import {Api} from "../../api/api";
import {logout} from "../login/login";
import {Utils} from "../../utils/utils";

//- Actions
export const fetchGetChartFailed = createAction('FETCH_GET_CHART_FAILED');
export const fetchGetChartSuccess = createAction('FETCH_GET_CHART_SUCCESS');
export const fetchGetChart = createAction(
    'FETCH_GET_CHART',
    (id) => Api.getChart(id),
    () => ({
        apiCall: true,
        onSuccess: fetchGetChartSuccess,
        onFail: fetchGetChartFailed,
        onUnauthorized: logout
    })
);

//- State
const initialState = {
    data: null,
    error: null,
    fetching: false
};

//- Reducers
export default handleActions({

    FETCH_GET_CHART: (state) => {
        return {...state, data: null, error: null, fetching: true};
    },
    FETCH_GET_CHART_FAILED: (state, action) => {
        return {...state, data: null, error: action.payload, fetching: false};
    },
    FETCH_GET_CHART_SUCCESS: (state, action) => {
        return {...state, data: action.payload, error: null, fetching: false};
    }

}, initialState);

//- Selectors
export const getChart = state => state.chart;

export const getChartData = state => {
    let data = getChart(state).data;

    if (!data) {
        return null;
    }

    return {
        id: data.id,
        name: data.name,
        interval: data.interval
    }
};

export const getBars = state => {
    let data = getChart(state).data;

    return data ? data.bars.map(bar => ({
        time: Utils.convertDatetimeToTimestamp(bar.datetime),
        open: bar.open,
        high: bar.high,
        low: bar.low,
        close: bar.close,
        volume: bar.volume
    })) : null;
};

export const loadingChart = state => getChart(state).fetching;

export const getChartError = state => getChart(state).error;

export const getChartId = state => {
    let data = getChart(state).data;

    if (!data) {
        return null;
    }

    return data.id
};