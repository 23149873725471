import React from 'react';
import * as queryString from 'query-string';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from "lodash";
import {MenuItem} from '@material-ui/core';

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export class Utils {
    static getParamsFromLocationHash(data) {
        return queryString.parse(data)
    }

    static formatTimeInterval(time) {
        if (!time) {
            return null;
        }

        let duration = dayjs.duration(time);
        let milliseconds = duration.milliseconds();
        let seconds = duration.seconds();
        let minutes = duration.minutes();
        let hours = duration.hours();

        let label = hours > 0 ? (hours + 'h ') : '';
        label += minutes > 0 ? (minutes + 'm ') : (hours > 0 ? '0m ' : '');
        label += seconds > 0 || milliseconds > 0 ? `${seconds}.${milliseconds}s` : '0s';

        return label;
    }

    static convertDatetimeToTimestamp(datetime) {
        return dayjs.utc(datetime, DATETIME_FORMAT).valueOf();
    }

    static convertTimestampToDatetime(timestamp) {
        return dayjs.utc(timestamp).format(DATETIME_FORMAT);
    }

    static convertPatternsForBars(patterns, bars) {
        return _.chain(patterns)
            .map(shapes => _.map(shapes, shape => ({
                max: {
                    time: _.maxBy(shape.points, point => point.time).time,
                    price: _.maxBy(shape.points, point => point.price).price
                },
                min: {
                    time: _.minBy(shape.points, point => point.time).time,
                    price: _.minBy(shape.points, point => point.price).price
                }
            })))
            .map(shapes => ({
                max: _.maxBy(shapes, shape => shape.max.time).max,
                min: _.minBy(shapes, shape => shape.min.time).min
            }))
            .map(boundary => {
                let maxIndex = _.findIndex(bars, bar => bar.time / 1000 > boundary.max.time);
                let minIndex = _.findIndex(bars, bar => bar.time / 1000 > boundary.min.time);
                maxIndex = maxIndex === -1 ? bars.length - 1 : maxIndex;
                minIndex = minIndex === -1 ? bars.length - 1 : minIndex;
                if (maxIndex === minIndex) {
                    if (minIndex === 0) {
                        maxIndex++;
                    } else {
                        minIndex--;
                    }
                }

                let barsInterval = _.chain(bars)
                    .slice(minIndex, maxIndex + 1)
                    .value();
                return [
                    {
                        price: boundary.min.price,
                        time: barsInterval[0].time / 1000
                    },
                    {
                        price: boundary.max.price,
                        time: barsInterval[barsInterval.length - 1].time / 1000
                    }
                ]
            })
            .value();
    }

    static getResolutionForInterval(interval) {
        let duration = dayjs.duration(interval);
        let days = duration.asDays();
        let hours = duration.asHours();
        return days > 1 ? Math.floor(days) + 'D' : hours * 60 + '';
    }

    static convertOptions(options) {
        if (!options) {
            return [];
        }

        return _.map(options, option =>
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        );
    }

    static formatHeatmapInterval(interval) {
        return dayjs.duration(interval * 60 * 1000).humanize();
    }

}