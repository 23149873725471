import React, {Component} from 'react';
import './TrainingPanel.css';
import PropTypes from 'prop-types';
import {Collapse, Grid, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import _ from "lodash";
import CustomSelect from "../../common/select/CustomSelect";
import CustomButton from "../../common/button/CustomButton";
import CustomSlider from "../../common/slider/CustomSlider";
import TerminalTextField from "../../common/textField/TerminalTextField";
import {MAX_PATTERNS_NUMBER} from "../../../reducers/patterns/patternsDetection";
import SelectPatternsWarningModal from "../../common/warningModal/SelectPatternsWarningModal.container";
import {Utils} from "../../../utils/utils";

const LABELS = {
    NOT_TRAINED: 'Number of selected patterns: {selected_number}\nSelect patterns and press \'Train model\' button to start model training...',
    TRAINING: 'Training model...',
    TRAINED: 'Number of selected patterns: {selected_number}\nNumber of training patterns: {number}\nTraining time: {time}\nModel confidence: {confidence}'
};

class TrainingPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedChart: '',
            statusFieldValue: LABELS.NOT_TRAINED.replace('{selected_number}', props.selectedPatternsNumber)
        };
    }

    render() {
        return (
            <div className="training-panel">
                {this.renderChartForm()}
                {this.renderModelForm()}
                <SelectPatternsWarningModal/>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.training && !prevProps.training) {
            this.setState({statusFieldValue: LABELS.TRAINING});
        }

        if (!this.props.training && !prevProps.confidence && this.props.confidence) {
            let label = LABELS.TRAINED;
            label = label.replace('{confidence}', this.props.confidence.toFixed(4));
            label = label.replace('{time}', this.props.trainingTime);
            label = label.replace('{number}', this.props.trainingPatternsNumber);
            label = label.replace('{selected_number}', this.props.selectedPatternsNumber);
            this.setState({statusFieldValue: label});
        }

        if (this.props.selectedPatternsNumber !== prevProps.selectedPatternsNumber) {
            let label = this.state.statusFieldValue;
            label = label.replace(prevProps.selectedPatternsNumber, this.props.selectedPatternsNumber);
            this.setState({statusFieldValue: label});
        }

        if (prevProps.modelCreated && !this.props.modelCreated) {
            let label = LABELS.NOT_TRAINED;
            label = label.replace('{selected_number}', this.props.selectedPatternsNumber);
            this.setState({statusFieldValue: label});
        }
    }

    componentDidMount() {
        if (_.isEmpty(this.state.selectedChart) && this.props.charts && this.props.charts.length !== 0) {
            this.setState({selectedChart: this.props.charts[0].value});
            this.props.onSelectChart(this.props.charts[0].value);
        }
    }

    renderChartForm = () => {
        //TODO: remove 'disabled={true}' when this functionality will be implemented
        return (
            <div className='form-container'>
                <Grid className='form-container' container spacing={2}>
                    <Grid item xs={12}>
                        Chart:
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <CustomSelect
                            className="custom-select"
                            variant='outlined'
                            value={this.state.selectedChart}
                            onChange={this.handleSelectChart}>
                            {Utils.convertOptions(this.props.charts)}
                        </CustomSelect>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton aria-label="delete" disabled={true}>
                            <PublishIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconButton aria-label="delete" disabled={true}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        );
    };

    handleSelectChart = (e) => {
        this.setState({selectedChart: e.target.value});
        this.props.onSelectChart(e.target.value);
    };

    renderModelForm = () => {
        let marks = [
            {
                value: 1,
                label: '1',
            },
            {
                value: this.props.maxSliderValue || MAX_PATTERNS_NUMBER,
                label: this.props.maxSliderValue || MAX_PATTERNS_NUMBER,
            },
        ];
        return (
            <div className='form-container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        Model
                    </Grid>
                    <Grid item xs={12}>
                        <CustomButton variant="outlined"
                                      disabled={this.props.training || this.props.detectingPatterns || this.props.loadingChart}
                                      onClick={this.onTrainModelButtonClick}>
                            Train model
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12}>
                        Training statistics:
                    </Grid>
                    <Grid item xs={12}>
                        <TerminalTextField
                            multiline
                            rows={5}
                            value={this.state.statusFieldValue}
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomButton variant="outlined"
                                      disabled={
                                          !this.props.modelCreated ||
                                          this.props.training ||
                                          this.props.detectingPatterns ||
                                          this.props.loadingChart
                                      }
                                      onClick={this.props.onDetectPatterns}>
                            Detect patterns
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse
                            in={!_.isNull(this.props.sliderValue)}>
                            Patterns number:
                            <CustomSlider value={this.props.sliderValue}
                                          disabled={this.props.training || this.props.detectingPatterns || !this.props.hasPatterns}
                                          min={1}
                                          max={this.props.maxSliderValue || MAX_PATTERNS_NUMBER}
                                          marks={marks}
                                          onChange={this.handleSliderChange}
                                          onChangeCommitted={this.handleSliderChangeCommitted}
                                          valueLabelDisplay='auto'/>
                        </Collapse>
                    </Grid>
                </Grid>
            </div>
        );
    };

    handleSliderChangeCommitted = (e, value) => {
        this.props.onSetConfidence(value);
    };

    handleSliderChange = (e, value) => {
        this.props.onSetSliderValue(value);
    };

    onTrainModelButtonClick = () => {
        this.props.onTrainModel();
    }
}

TrainingPanel.propTypes = {
    charts: PropTypes.array,
    modelCreated: PropTypes.bool,
    training: PropTypes.bool,
    detectingPatterns: PropTypes.bool,
    loadingChart: PropTypes.bool,
    maxSliderValue: PropTypes.number,
    sliderValue: PropTypes.number,
    hasPatterns: PropTypes.bool,
    confidence: PropTypes.number,
    trainingTime: PropTypes.string,
    trainingPatternsNumber: PropTypes.number,
    selectedPatternsNumber: PropTypes.number,
    onSelectChart: PropTypes.func.isRequired,
    onTrainModel: PropTypes.func.isRequired,
    onSetConfidence: PropTypes.func.isRequired,
    onDetectPatterns: PropTypes.func.isRequired,
    onSetSliderValue: PropTypes.func.isRequired,
};

export default TrainingPanel;
